import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { OM_API_URL } from '../BaseURL';
import { useGoogleLogin, useGoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';
import PhoneInput from 'react-phone-input-2';
import { toast, Toaster } from 'react-hot-toast';
import AppleLogin from 'react-apple-login'

const SignUp = () => {

    const [details, setDetails] = useState({
        fname: "", lname: "", email: "", number: "", password: "", cpassword: "", pwdShow: true,
        cpwdShow: true, terms: ""
    })
    const [error, setError] = useState({});
    const nav = useNavigate();

    const handleChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value })
    }

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const handleSignUp = (e) => {
        e.preventDefault();
        if (validation()) {
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('firstName', details.fname);
            bodyFormData.append('lastName', details.lname);
            bodyFormData.append('email', details.email);
            bodyFormData.append('password', details.password);
            // bodyFormData.append('cpassword', details.cpassword);
            bodyFormData.append('mobileno', details.number);
            bodyFormData.append('login_type', '1');
            bodyFormData.append('fcm_token', '1');
            bodyFormData.append('timezone', userTimezone);
            bodyFormData.append('social_id', responseGoogle);
            axios({
                method: 'post',
                url: `${OM_API_URL}/customer/sign-up`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                if (response?.data?.status === true) {
                    nav('/otp-verification', { state: { email: details.email, type: 'signup' } })
                }
            }).catch((err) => {
                console.log(err);
                setError({ email_err: "Email is already used" })
            })
        }
    }

    const { signOut } = useGoogleLogout({
        clientId: "1047790433688-bm05f0ehoghcus8o8qn7el61ogdm46u3.apps.googleusercontent.com"
    })


    const onFailure = (res) => {
        console.log('Sign-in failed', res);
        signOut();
    }

    const responseGoogle = (googleUser) => {
        console.log('Google Sign-In successful');
        console.log('Google user information:', googleUser);

        const bodyFormData = new URLSearchParams();
        bodyFormData.append('firstName', googleUser.profileObj.familyName);
        bodyFormData.append('lastName', googleUser.profileObj.givenName);
        bodyFormData.append('email', googleUser.profileObj.email);
        bodyFormData.append('login_type', '3');
        bodyFormData.append('fcm_token', '1');
        bodyFormData.append('timezone', userTimezone);
        bodyFormData.append('social_id', googleUser.profileObj.googleId);
        axios({
            method: 'post',
            url: `${OM_API_URL}/customer/sign-up`,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then((response) => {
            console.log(response.data, "data")
            if (response.data.status === true) {
                nav('/otp-verification', { state: { email: googleUser.profileObj.email, type: 'login' } })
            }
            // localStorage.setItem("OM_TOKEN_USER", googleUser.accessToken)
        }).catch((err) => {
            console.log(err);
            setError(err)
            toast.error(err.response.data.message)
            console.log("data err")
        })


        // const idToken = googleUser.accessToken;

        // console.log(idToken, "id")
        // localStorage.setItem("OM_TOKEN_USER", googleUser.accessToken)

        // nav('/');
        // window.location.reload();
    };



    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: "1047790433688-bm05f0ehoghcus8o8qn7el61ogdm46u3.apps.googleusercontent.com",
                scope: 'email',
            });
        }
        gapi.load('client:auth2', (err) => { callback(err) }, start);

        document.addEventListener("AppleIDSignInOnSuccess", (event) => {
            // Handle successful response.
            console.log("Success ", event);
        });
    }, []);

    function callback(err) {
        if (err) {
            console.log(err);
            return;
        }
    }

    const { signIn } = useGoogleLogin({
        onSuccess: responseGoogle,
        onFailure,
        clientId: "1047790433688-bm05f0ehoghcus8o8qn7el61ogdm46u3.apps.googleusercontent.com",
        isSignedIn: 'true',
        prompt: 'select_account'
    })

    const validation = () => {
        let isValid = true;
        let err = {};

        if (!details.fname) {
            isValid = false;
            err['fname_err'] = "Please enter first name"
        }

        if (!details.lname) {
            isValid = false;
            err['lname_err'] = "Please enter last name"
        }

        if (!details.email) {
            isValid = false;
            err['email_err'] = "Please enter email"
        } else if (typeof details.email !== "undefined") {
            let lastAtPos = details.email.lastIndexOf('@');
            let lastDotPos = details.email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && details.email.indexOf('@@') === -1 && lastDotPos > 2 && (details.email.length - lastDotPos) > 2)) {
                isValid = false;
                err["email_err"] = "Email is not valid";
            }
        }
        // Assuming details.number contains the mobile number
        if (!details.number) {
            isValid = false;
            err['mobile_err'] = "Please enter a mobile number";
        } else {
            const phoneRegex = /^\d{1,2}-\d{10}$/; // Updated regular expression for phone number validation
            if (!phoneRegex.test(details.number)) {
                isValid = false;
                err['mobile_err'] = "Please enter a valid mobile number";
            }
        }


        if (!details.password) {
            isValid = false;
            err['password_err'] = "Please enter password"
        } else if (details.password.toLowerCase() === details.password) {
            isValid = false;
            err['password_err'] = "Password must contain at least one uppercase letter";
        }

        if (!details.cpassword) {
            isValid = false;
            err['cpassword_err'] = "Please enter confirm password"
        }
        if (details.password && details.cpassword) {
            if (details.password.toLowerCase() !== details.cpassword.toLowerCase()) {
                isValid = false;
                err['cpassword_err'] = "Password doesn't match";
            }
        }
        if (!details.terms) {
            isValid = false;
            err['terms_err'] = "Please select the terms & conditions"
        }

        setError(err);
        return isValid;
    }

    const [isChecked, setIsChecked] = useState(false);


    function handleCheckboxChange(e) {
        setDetails({ ...details, [e.target.name]: e.target.checked });
        setIsChecked(e.target.checked);
    }

    // const handleApple = (response) => {
    //     console.log(response)
    //     console.log("click")

    //     const bodyFormData = new URLSearchParams();
    //     bodyFormData.append('firstName', );
    //     bodyFormData.append('lastName', );
    //     bodyFormData.append('email', );
    //     bodyFormData.append('login_type', '4');
    //     bodyFormData.append('fcm_token', '1');
    //     bodyFormData.append('timezone', userTimezone);
    //     bodyFormData.append('social_id', "tuCfRz17E6a5AsmYizVXNIrPGsj1");
    //     axios({
    //         method: 'post',
    //         url: `${OM_API_URL}/customer/sign-up`,
    //         data: bodyFormData,
    //         headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //     }).then((response) => {
    //         console.log(response.data, "data")
    //         if (response.data.status === true) {
    //             // nav('/otp-verification', { state: { email: googleUser.profileObj.email, type: 'login' } })
    //         }
    //         // localStorage.setItem("OM_TOKEN_USER", googleUser.accessToken)

    //     }).catch((err) => {
    //         console.log(err);
    //         setError(err)
    //         toast.error(err.response.data.message)
    //         console.log("data err")
    //     })
    // }

    // useEffect(() => {
    //     handleAppleSuccess();
    //     handleAppleFailure();
    // }, [])


    const handleAppleSuccess = (response) => {
        console.log(response, 'click')

        const appleID = response.user;
        const firstName = appleID.user?.firstName || "";
        const lastName = appleID.user?.lastName || "";
        const email = appleID.user.accountName || ""; // If Apple provided the user's email address
        const userID = appleID.id; // User ID from the Apple login response

        const bodyFormData = new URLSearchParams();
        bodyFormData.append('firstName', firstName);
        bodyFormData.append('lastName', lastName);
        bodyFormData.append('email', email);
        bodyFormData.append('login_type', '4');
        bodyFormData.append('fcm_token', '1');
        bodyFormData.append('timezone', userTimezone);
        bodyFormData.append('social_id', userID);

        axios({
            method: 'post',
            url: `${OM_API_URL}/customer/sign-up`,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
            .then((response) => {
                console.log(response.data, "data");
                if (response.data.status === true) {
                    const authToken = response.data.token; // Authentication token from the signup response
                    // Store the user ID and authentication token for future use
                    // You can save them in local storage, state management, or any other preferred method
                    console.log("User ID:", userID);
                    console.log("Authentication Token:", authToken);

                    // Perform actions after a successful signup
                    // nav('/otp-verification', { state: { email: googleUser.profileObj.email, type: 'login' } })
                }
            })
            .catch((err) => {
                console.log(err);
                setError(err);
                toast.error(err.response.data.message);
                console.log("data err");
            });
    };

    const handleAppleFailure = (response) => {
        console.log("Failure", response)
    }


    return (
        <div>
            <Toaster />
            <main className="rd-site-content">
                <section className="rd-login-section">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-6" >
                            <div className="rd-login-content om-signup-content" >
                                <a href="/" className="rd-login-logo">
                                    <span className="rd-login-logo__img">
                                        <img src="assets/images/logo.svg" alt="logo" className="img-fluid" />
                                    </span>
                                </a>
                                <div className="rd-login-form-content" style={{ overflow: "auto" }}>
                                    <div className="rd-login-form-heading">
                                        <h1 className="rd-login-form-title">Sign-Up</h1>
                                        <p className="rd-login-form-description">Enter Details</p>
                                    </div>
                                    <div className="rd-login-form">
                                        <form className="row" autocomplete="off">
                                            <div className="col-md-12 col-lg-6">
                                                <label htmlFor="inputUsername" className="form-label">First name</label>
                                                <input type="text" className="form-control" id="inputUsername" name='fname' value={details.fname} onChange={handleChange} />
                                                <span className='error'>{error.fname_err}</span>
                                            </div>
                                            <div className="col-md-12 col-lg-6">
                                                <label htmlFor="inputPassword4" className="form-label">Last name</label>
                                                <input type="text" className="form-control" id="inputPassword4" name='lname' value={details.lname} onChange={handleChange} />
                                                <span className='error'>{error.lname_err}</span>
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="inputPassword4" className="form-label">Email Address </label>
                                                <input type="email" className="form-control" id="inputPassword4" name='email' value={details.email} onChange={handleChange} />
                                                <span className='error'>{error.email_err}</span>
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="inputPassword4" className="form-label">Mobile Number</label>
                                                {/* <input type="number" className="form-control no-spinner" id="inputPassword4" name='number' value={details.number} onChange={handleChange} /> */}
                                                {/* <PhoneInput
                                                inputExtraProps={{
                                                    name: "number",
                                                    required: true,
                                                    autoFocus: true
                                                }}
                                                placeholder=""
                                                name="number"
                                                defaultCountry={"us"}
                                                value={details.number}
                                                onChange={(val) => setDetails({ ...details, number : val })}
                                            /> */}
                                                <PhoneInput
                                                    inputExtraProps={{
                                                        name: "number",
                                                        required: true,
                                                        autoFocus: true
                                                    }}
                                                    placeholder=""
                                                    name="number"
                                                    defaultCountry={"us"}
                                                    value={details.number}
                                                    onChange={(val, countryData) => {
                                                        const numericValue = val.replace(/[^0-9]/g, ""); // Extract numeric value only
                                                        const countryCodePrefix = "+" + countryData.dialCode; // Get country code prefix from countryData

                                                        let formattedValue = numericValue;
                                                        if (numericValue.startsWith(countryData.dialCode)) {
                                                            formattedValue = `${countryData.dialCode}-${numericValue.substring(countryData.dialCode.length)}`;
                                                        }
                                                        console.log(formattedValue)
                                                        setDetails({ ...details, number: formattedValue });
                                                    }}
                                                />

                                                <span className='error'>{error.mobile_err}</span>
                                            </div>
                                            <div className="col-md-12 col-lg-6">
                                                <label htmlFor="inputPassword4" className="form-label">Password</label>
                                                <div className="password-field">
                                                    <input type={details.pwdShow ? "password" : "text"} className={error.password_err ? "form-control error" : "form-control"} id="inputPassword5" name="password" onChange={handleChange} value={details.password} />
                                                    {details.pwdShow ? <i className='fa fa-eye' onClick={() => setDetails({ ...details, pwdShow: false })} /> : <i className='fa fa-eye-slash' onClick={() => setDetails({ ...details, pwdShow: true })} />}
                                                </div>
                                                <span className='error'>{error.password_err}</span>
                                            </div>
                                            <div className="col-md-12 col-lg-6">
                                                <label htmlFor="inputPassword4" className="form-label">Confirm Password</label>
                                                <div className="password-field">
                                                    <input type={details.cpwdShow ? "password" : "text"} className={error.cpassword_err ? "form-control error" : "form-control"} id="inputPassword6" name="cpassword" onChange={handleChange} value={details.cpassword} />
                                                    {details.cpwdShow ? <i className='fa fa-eye' onClick={() => setDetails({ ...details, cpwdShow: false })} /> : <i className='fa fa-eye-slash' onClick={() => setDetails({ ...details, cpwdShow: true })} />}
                                                </div>
                                                <span className='error'>{error.cpassword_err}</span>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-check terms-conditions-check">
                                                    <input className="form-check-input" type="checkbox" id="gridCheck" onChange={handleCheckboxChange} value={details.terms} name='terms' checked={isChecked} />
                                                    <label className="form-check-label" htmlFor="gridCheck">
                                                        I agree to the <a href="/terms-and-conditions">Terms & Conditions</a>
                                                    </label>
                                                </div>
                                                <span className='error'>{error.terms_err}</span>
                                            </div>
                                            <div className="col-12">
                                                <button type='button' onClick={handleSignUp}
                                                    className="btn btn-dark login-btn w-100" >Register</button>
                                            </div>
                                            <div className="col-12">
                                                <ul className="login-with-social">
                                                    <li className="login-with-social-item">
                                                        <button type='button' onClick={signIn} className="login-with-social-link">
                                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M26.625 15.2727C26.625 14.4277 26.5542 13.6127 26.4207 12.8328H15.2354V17.4543H21.6419C21.3667 18.9609 20.5349 20.2427 19.2729 21.101L23.0964 24.1026C25.3351 22.0127 26.625 18.9243 26.625 15.2727Z"
                                                                    fill="white" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M15.2362 27.0001C18.437 27.0001 21.132 25.9368 23.0973 24.1034L19.2738 21.1018C18.2096 21.8268 16.839 22.2501 15.2362 22.2501C12.1426 22.2501 9.51669 20.1402 8.57769 17.2969L4.63892 20.3885C6.59098 24.3084 10.6022 27.0001 15.2362 27.0001Z"
                                                                    fill="white" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M8.57726 17.2965C8.33677 16.5715 8.20493 15.7982 8.20493 14.9999C8.20493 14.2016 8.33677 13.4282 8.57726 12.7032L4.63849 9.61157C3.8313 11.2316 3.375 13.0616 3.375 14.9999C3.375 16.9381 3.8313 18.7683 4.63849 20.3881L8.57726 17.2965Z"
                                                                    fill="white" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M15.2362 7.74997C16.984 7.74997 18.5506 8.35822 19.7861 9.54994V9.55163L23.1714 6.12664C21.1156 4.18999 18.4353 3 15.2362 3C10.6022 3 6.59098 5.69164 4.63892 9.61159L8.57773 12.7032C9.51669 9.85991 12.1426 7.74997 15.2362 7.74997Z"
                                                                    fill="white" />
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li className="login-with-social-item">
                                                        {/* <button type='button' onClick={handleApple} className="login-with-social-link">
                                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M24.8432 11.1861C24.7286 11.2529 22.0006 12.6637 22.0006 15.7918C22.1292 19.3591 25.4432 20.6102 25.5 20.6102C25.4432 20.677 24.9997 22.3144 23.686 24.0308C22.6435 25.5093 21.4863 27 19.7292 27C18.0578 27 17.4578 26.0146 15.5292 26.0146C13.458 26.0146 12.872 27 11.2863 27C9.52917 27 8.28631 25.4295 7.18692 23.9648C5.75866 22.0479 4.54467 19.0397 4.50181 16.1512C4.47293 14.6206 4.78786 13.116 5.58721 11.8381C6.71547 10.054 8.72978 8.84285 10.9295 8.80291C12.6149 8.74996 14.1149 9.88122 15.1434 9.88122C16.1292 9.88122 17.972 8.80291 20.0571 8.80291C20.9571 8.80381 23.3571 9.05644 24.8432 11.1861ZM15.0009 8.49732C14.7009 7.09955 15.5292 5.70177 16.3006 4.81017C17.2863 3.73188 18.8432 3 20.1857 3C20.2714 4.39778 19.7283 5.76863 18.7574 6.76703C17.8863 7.84531 16.3863 8.65707 15.0009 8.49732Z"
                                                                    fill="white" />
                                                            </svg>
                                                        </button> */}
                                                        <AppleLogin
                                                            clientId="com.omathomeapp"
                                                            redirectURI="https://omathomeapp.com"
                                                            onSuccess={handleAppleSuccess}
                                                            responseMode='query'
                                                            responseType='code'
                                                            nonce='string'
                                                            scope='name email'
                                                            state="origin:web"
                                                            onFailure={handleAppleFailure}
                                                            render={renderProps => (
                                                                <button
                                                                    type="button"
                                                                    onClick={renderProps.onClick}
                                                                    className="login-with-social-link"
                                                                >
                                                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M24.8432 11.1861C24.7286 11.2529 22.0006 12.6637 22.0006 15.7918C22.1292 19.3591 25.4432 20.6102 25.5 20.6102C25.4432 20.677 24.9997 22.3144 23.686 24.0308C22.6435 25.5093 21.4863 27 19.7292 27C18.0578 27 17.4578 26.0146 15.5292 26.0146C13.458 26.0146 12.872 27 11.2863 27C9.52917 27 8.28631 25.4295 7.18692 23.9648C5.75866 22.0479 4.54467 19.0397 4.50181 16.1512C4.47293 14.6206 4.78786 13.116 5.58721 11.8381C6.71547 10.054 8.72978 8.84285 10.9295 8.80291C12.6149 8.74996 14.1149 9.88122 15.1434 9.88122C16.1292 9.88122 17.972 8.80291 20.0571 8.80291C20.9571 8.80381 23.3571 9.05644 24.8432 11.1861ZM15.0009 8.49732C14.7009 7.09955 15.5292 5.70177 16.3006 4.81017C17.2863 3.73188 18.8432 3 20.1857 3C20.2714 4.39778 19.7283 5.76863 18.7574 6.76703C17.8863 7.84531 16.3863 8.65707 15.0009 8.49732Z" fill="white" />
                                                                    </svg>
                                                                </button>
                                                            )}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-12">
                                                <div className="text-center">
                                                    <p className="login-notes">Already Have an Account ? <Link to="/login">Sign
                                                        In</Link></p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-6">
                            {window.innerWidth > 767 ?
                                <div className="rd-login-img">
                                    <img src="assets/images/login_img.jpg" alt="login-img" className="img-fluid w-100" />
                                </div>
                                : ""}
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default SignUp