import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { OM_API_URL } from '../BaseURL';
import Footer from './Footer';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';



const SubscriptionPlan = () => {

    let navigate = useNavigate()
    useEffect(() => {
        getSubscriptionPlan();
        getPlan();
    }, []);

    const [plans, setPlans] = useState([]);
    const [plansTwo, setPlansTwo] = useState([]);
    const [planDetails, setPlanDetails] = useState([])
    const [currentPlanId, setCurrentPlanId] = useState([])


    const getSubscriptionPlan = () => {
        axios({
            method: 'post',
            url: `${OM_API_URL}/customer/get-subscription-plans`,
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('OM_TOKEN_USER')}` }
        }).then(async (response) => {
            if (response.data.status === true) {
                console.log(response.data)
                let data = await Promise.all(response.data.data.plans.map(elem => {
                    return { ...elem, description: elem.description.split('\n') }
                }))
                setPlans(data)
                if (response.data.data.active_plan !== '') {
                    const detail = response.data.data.active_plan
                    // console.log(detail, "plan detaisl")
                    setPlanDetails(detail)
                    setCurrentPlanId(detail._id)
                }
            }
        })
    }

    const getPlan = () => {
        axios({
            method: 'get',
            url: `${OM_API_URL}/web/get-subscription-plans`,
            headers: { 'Content-Type': 'application/json' }
        }).then(async (response) => {
            if (response.data.status === true) {
                // console.log(response.data.data)
                let data = await Promise.all(response.data.data.map(elem => {
                    return { ...elem, description: elem.description.split('\n') }
                }))
                setPlansTwo(data)
            }
        }).catch((err) => {
            console.log(err)
            if (err.response.status === 401) {
                localStorage.removeItem('OM_TOKEN_USER')
                navigate('/login')
            }
        })
    }

    const handleUserSubscription = (id) => {
        if (localStorage.getItem('OM_TOKEN_USER') === null) {
            navigate('/sign-up')
        } else {
            navigate('/payment', { state: id })
        }
    }

    const handleUserSubscriptionFree = (id) => {
        if (localStorage.getItem('OM_TOKEN_USER') === null) {
            navigate('/sign-up')
        }
        else {
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('subscription_plan_id', id);
            axios({
                method: 'post',
                url: `${OM_API_URL}/customer/start-subscription`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded ', 'Authorization': `Bearer ${localStorage.getItem('OM_TOKEN_USER')}` }
            }).then((response) => {
                console.log(response, "response")
                if (response.data?.status === true) {
                    // toast.success(response.data?.message, { style: { background: '#333', color: '#fff' } });
                    toast.success(" Congratulations you’re subscribed", { style: { background: '#333', color: '#fff' } });
                    setTimeout(() => {
                        navigate('/');
                        window.location.reload();
                    }, 1500)
                    console.log("data subscribe")
                }
                else {
                    // console.log("data subscribe not subscribe")
                    // toast.error(response.data.message, { style: { background: '#333', color: '#fff' } })
                }
            }).catch((err) => {
                toast.error(err.response.data.message, { style: { background: '#333', color: '#fff' } })
                if (err.response.status === 401) {
                    localStorage.removeItem('OM_TOKEN_USER')
                    navigate('/login')
                }
            })
        }
    }

    const checkPlan = (id) => {
        let isVlaid = false;
        if (plans.filter(pl => pl._id === id)[0].amount === 0 && currentPlanId !== id) { isVlaid = true } else { isVlaid = false }
        return isVlaid
    }

    const handleBusiness = () => {
        window.location.href = "https://group.omathomeapp.com/login";
    }

    return (
        <div>
            <Toaster />
            <Header />
            {/* <section className="subscription-section" id="subscription">
                <div className="container">
                    <div className="subscription-content">
                        <h2 className="section-title">Choose Your Plan </h2>
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-individual-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-individual" type="button" role="tab"
                                    aria-controls="pills-individual" aria-selected="true">Individual</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-business-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-business" type="button" role="tab" aria-controls="pills-business"
                                    aria-selected="false">Group</button>
                            </li>
                        </ul>
                        <div className="tab-content subscription-tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-individual" role="tabpanel"
                                aria-labelledby="pills-individual-tab">
                                <div className="row">
                                    {plans.length > 0 && plans.map((elem, index) =>

                                        <div className="col col-12 col-md-6 col-lg-4">
                                            <div className={`subscription-plans ${index === 1 ? 'active' : ''}`} key={elem.id}>
                                                <div className="subscription-plans-details">
                                                    <h4 className="subscription-title">{elem.plan_name}</h4>
                                                    <h3 className="subscription-price free">
                                                        {index === 1 ? `$ ${elem.amount} ($6.58/ month)` : `$ ${elem.amount}`}
                                                    </h3>
                                                    {elem.description.map(e =>
                                                        <ul className="subscription-plans-feature">
                                                            <li>{e.split('. ')[1]}</li>
                                                        </ul>
                                                    )}
                                                </div>
                                                <div className="subscription-plans-footer">
                                                    {elem.amount === 0 ? (
                                                        <button type="button" onClick={() => handleUserSubscriptionFree(elem._id)}
                                                            disabled={currentPlanId ? checkPlan(elem._id) : false} className="btn btn-subscription">
                                                            {elem._id === currentPlanId ? 'Current Plan - Try For Free' : 'Try For Free'}
                                                        </button>
                                                    ) : (
                                                        <button type="button" onClick={() => handleUserSubscription(elem._id)}
                                                          
                                                            disabled={(elem._id === currentPlanId) || (planDetails.duration === 'Yearly plan')}
                                                            
                                                            className="btn btn-subscription">
                                                            {elem._id === currentPlanId ? 'Current Plan' : 'Subscribe'}
                                                        </button>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-business" role="tabpanel"
                                aria-labelledby="pills-business-tab">
                                <div className="row" style={{ display: "flex", justifyContent: "center" }}>
                                    {plansTwo.length > 0 && plansTwo.map((element, index) =>
                                        <div className="col col-12 col-md-6 col-lg-4">
                                            <div className={`subscription-plans ${index === 1 ? 'active' : ''}`} key={element.id}>
                                                
                                                <div className="subscription-plans-details">
                                                    <h4 className="subscription-title">{element.plan_name}</h4>
                                                    <h3 className="subscription-price free">$ {element.amount}</h3>
                                                    {element.description.map(e =>
                                                        <ul className="subscription-plans-feature">
                                                            <li>{e.split('. ')[1]}</li>
                                                        </ul>
                                                    )}
                                                </div>
                                                <div className="subscription-plans-footer">
                                                    <button type="button" onClick={handleBusiness} className="btn btn-subscription">Subscribe</button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section> */}
            <section className="subscription-section" id="subscription">
                <div className="container">
                    <div className="subscription-content">
                        <h2 className="section-title mb-3 mt-3">Choose Your Plan </h2>
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-individual-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-individual" type="button" role="tab"
                                    aria-controls="pills-individual" aria-selected="true">Individual</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-business-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-business" type="button" role="tab" aria-controls="pills-business"
                                    aria-selected="false">Group</button>
                            </li>
                        </ul>
                        <div className="tab-content subscription-tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-individual" role="tabpanel"
                                aria-labelledby="pills-individual-tab">
                                {/* 
                        <div className='subs-new-plans'>
                            <div className="row" style={{ display: "flex", justifyContent: "center" }}>
                                {plans.slice(0, 2).map((elem, index) => (
                                    <div className="col col-4 col-md-6 col-lg-4" key={elem.id}>
                                        <div className={`subscription-plans ${index === 1 ? 'active' : ''}`}>
                                            <div className="subscription-plans-details">
                                                <h4 className="subscription-title">{elem.plan_name}</h4>
                                                {index === 1 ? (
                                                    <h3 className="subscription-price free">$ {elem.amount}</h3>
                                                ) : (
                                                    <h3 className="subscription-price">$ {elem.amount}</h3>
                                                )}
                                                {elem.description.map(e => (
                                                    <ul className="subscription-plans-feature" key={e}>
                                                        <li>{e.split('. ')[1]}</li>
                                                    </ul>
                                                ))}
                                            </div>
                                            <div className="subscription-plans-footer">
                                                {elem.amount === 0 ? (
                                                    <button
                                                        type="button"
                                                        onClick={() => handleUserSubscriptionFree(elem._id)}
                                                        disabled={currentPlanId ? checkPlan(elem._id) : false}
                                                        className="btn btn-subscription"
                                                    >
                                                        {elem._id === currentPlanId ? 'Current Plan - Try For Free' : 'Try For Free'}
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        onClick={() => handleUserSubscription(elem._id)}
                                                        disabled={(elem._id === currentPlanId) || (planDetails.duration === 'Yearly plan')}
                                                        className="btn btn-subscription"
                                                    >
                                                        {elem._id === currentPlanId ? 'Current Plan' : 'Subscribe'}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {plansTwo.length > 0 && (
                                    <div className="col col-4 col-md-6 col-lg-4" key={plansTwo[0].id}>
                                        <div className={`subscription-plans`}>
                                            <div className="subscription-plans-details">
                                              
                                                <h4 className="subscription-title">
                                                    {plansTwo[0].plan_name.split(' ').map((word, index) => (
                                                        <span key={index}>
                                                            {word}
                                                            {index === 0 ? <br /> : ' '}
                                                        </span>
                                                    ))}
                                                </h4>
                                                <h3 className="subscription-price free">$ {plansTwo[0].amount}</h3>
                                                {plansTwo[0].description.map(e => (
                                                    <ul className="subscription-plans-feature" key={e}>
                                                        <li>{e.split('. ')[1]}</li>
                                                    </ul>
                                                ))}
                                            </div>
                                            <div className="subscription-plans-footer">
                                                <button
                                                    type="button"
                                                    onClick={handleBusiness}
                                                    className="btn btn-subscription"
                                                >
                                                    Subscribe
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div> */}
                                <div className="row">
                                    {plans.length > 0 && plans.map((elem, index) =>
                                        <div className="col col-4 col-md-6 col-lg-4">
                                            <div className={`subscription-plans ${index === 1 ? 'active' : ''}`} key={elem.id}>
                                                <div className="subscription-plans-details">
                                                    <h4 className="subscription-title">{elem.plan_name}</h4>
                                                    {index === 1 ? (
                                                        <h3 className="subscription-price free">
                                                            $ {elem.amount}
                                                            {/* <p className='subscription-price-plan-details'> $6.58/ month </p> */}
                                                        </h3>
                                                    ) : (
                                                        <h3 className="subscription-price">
                                                            $ {elem.amount}
                                                        </h3>
                                                    )}
                                                    {elem.description.map(e =>
                                                        <ul className="subscription-plans-feature">
                                                            <li>{e.split('. ')[1]}</li>
                                                        </ul>
                                                    )}

                                                </div>
                                                <div className="subscription-plans-footer">
                                                    {elem.amount === 0 ? (
                                                        <button type="button" onClick={() => handleUserSubscriptionFree(elem._id)}
                                                            disabled={currentPlanId ? checkPlan(elem._id) : false} className="btn btn-subscription">
                                                            {elem._id === currentPlanId ? 'Current Plan - Try For Free' : 'Try For Free'}
                                                        </button>
                                                    ) : (
                                                        <button type="button" onClick={() => handleUserSubscription(elem._id)}
                                                            // disabled={elem._id === currentPlanId}
                                                            disabled={(elem._id === currentPlanId) || (planDetails.duration === 'Yearly plan')}
                                                            className="btn btn-subscription">
                                                            {elem._id === currentPlanId ? 'Current Plan' : 'Subscribe'}
                                                        </button>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-business" role="tabpanel"
                                aria-labelledby="pills-business-tab">
                                <div className="row" style={{ display: "flex", justifyContent: "center" }}>
                                    {plansTwo.length > 0 && plansTwo.map((element, index) =>
                                        <div className="col col-4 col-md-6 col-lg-4">
                                            <div className={`subscription-plans ${index === 1 ? 'active' : ''}`} key={element.id}>
                                                <div className="subscription-plans-details">
                                                    <h4 className="subscription-title">{element.plan_name}</h4>

                                                    {index === 0 ? (
                                                        <h3 className="subscription-price free">
                                                            $ {element.amount} 
                                                            {/* <p className='subscription-price-plan-details'> $1/month per user </p> */}
                                                        </h3>
                                                    ) : (
                                                        <h3 className="subscription-price">
                                                            $ {element.amount} 
                                                            {/* <p className='subscription-price-plan-details'>$6.58/month</p> */}
                                                        </h3>
                                                    )}

                                                    {element.description.map(e =>
                                                        <ul className="subscription-plans-feature">
                                                            <li>{e.split('. ')[1]}</li>
                                                        </ul>
                                                    )}
                                                </div>
                                                <div className="subscription-plans-footer">
                                                    <button type="button" onClick={handleBusiness} className="btn btn-subscription">Subscribe</button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default SubscriptionPlan