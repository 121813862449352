import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import axios from 'axios'

import { OM_API_URL } from '../BaseURL';
// import { useNavigate } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';

const Blog = () => {

    const [blog, setBlog] = useState([]);
    const nav = useNavigate();
    const [Loading, setLoading] = useState(false)


    useEffect(() => {
        getBlog();
    }, [])

    const getBlog = () => {

        setLoading(true)
        const myurl = `${OM_API_URL}/customer/get-blogs`;

        axios({
            method: "get",
            url: myurl,
            // data: fd,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('OM_Admin_ID') },
        }).then(async (response) => {
            console?.log("@@", response?.data);
            if (response?.data?.status) {
                setBlog(response?.data?.data);
                setLoading(false)
            } else {
                toast.error("Something went wrong");
                setLoading(false);
            }
        }).catch((error) => {
            toast.error("Something went wrong");
            console?.log("Errors", error);
            setLoading(false);
        });
    }


    return (
        <div>
            <Header />

            <section className="blog-section">
                <div className="container">
                    <h1 className="section-title text-start">Blog</h1>

                    <div className="blog-item-content">
                        {Loading ? (
                            <div style={{ textAlign: "center" }}>
                                <h3>Loading...</h3>
                            </div>
                        ) : (
                            blog.length === 0 ? (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <img src='/assets/images/no-location.png' className='img-fluid'
                                        style={{ width: "100px", height: "100px" }}
                                    />
                                </div>
                            ) : (
                                blog.map((e) =>
                                    <div className="blog-item">
                                        <div className="row">
                                            <div className="col col-12 col-md-6 col-lg-6">
                                                <div className="blog-card">
                                                    <h3 className="blog-card-title">{e?.title}</h3>
                                                    <p className="blog-card-description">
                                                        {e?.description?.length > 350 ? e.description.slice(0, 350) + '...' : e.description || ""}
                                                    </p>
                                                    <span onClick={() => nav("/blog-details", { state: e })} className="blog-card-link" style={{ cursor: "pointer" }}>Read More</span>
                                                </div>
                                            </div>
                                            <div className="col col-12 col-md-6 col-lg-5">
                                                <div className="blog-card-img">
                                                    <img src={e?.image} alt="blog1" className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )


                        )}
                        {/* <div className="blog-item">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="blog-card">
                                        <h3 className="blog-card-title">Om's 5 Proven Ways to Win an Argument</h3>
                                        <p className="blog-card-description">No one likes to lose an argument, but winning one
                                            can be a challenge. Fortunately, there are techniques you can use to create a
                                            better outcome. Here are 5 proven ways to “win” an argument, with examples of
                                            how to use each one effectively</p>
                                        <Link to="/blog-details" className="blog-card-link">Read More</Link>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-5">
                                    <div className="blog-card-img">
                                        <img src="assets/images/blog2.png" alt="blog1" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-item">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="blog-card">
                                        <h3 className="blog-card-title">Yes, Being a Minority Takes a Toll on Mental Health</h3>
                                        <p className="blog-card-description">Racism and discrimination have a profound impact on
                                            the mental health and well-being of people of color. From microaggressions to
                                            systemic oppression, racism can cause significant stress and trauma that can
                                            lead to a range of mental health disorders, including depression, anxiety, and
                                            post-traumatic stress disorder (PTSD).</p>
                                        <Link to="/blog-details" className="blog-card-link">Read More</Link>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-5">
                                    <div className="blog-card-img">
                                        <img src="assets/images/blog3.png" alt="blog1" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-item">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-6">
                                    <div className="blog-card">
                                        <h3 className="blog-card-title">Use this Surprising Technique to Boost Your Meditation
                                            Practice</h3>
                                        <p className="blog-card-description">When it comes to meditation and mindfulness, many
                                            people think of it as a solitary practice. While there are certainly benefits to
                                            practicing on your own, there is also a lot to be gained from meditating and
                                            healing with others. This is especially true for those who have experienced
                                            trauma, where social support can be critical in the healing process.</p>
                                        <Link to="/blog-details" className="blog-card-link">Read More</Link>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-5">
                                    <div className="blog-card-img">
                                        <img src="assets/images/blog4.png" alt="blog4" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default Blog