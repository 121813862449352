import React, { useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import { OM_API_URL } from '../BaseURL';
import { useNavigate } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { Modal } from 'react-bootstrap';


const ContactUs = () => {

    const [contact, setContact] = useState({ name: "", number: " ", email: "", message: "" })
    const [error, setError] = useState({});
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleChange = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value })
    }

    const navigate = useNavigate();

    const handleSend = (e) => {
        e.preventDefault();
        if (localStorage.getItem('OM_TOKEN_USER') === null) {
            navigate('/login')
        } else {
            if (validation()) {
                const bodyFormData = new URLSearchParams();
                bodyFormData.append('name', contact.name);
                bodyFormData.append('email', contact.email);
                bodyFormData.append('number', contact.number);
                bodyFormData.append('message', contact.message);

                axios({
                    method: 'post',
                    url: `${OM_API_URL}/customer/contact-us`,
                    data: bodyFormData,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Bearer ${localStorage.getItem('OM_TOKEN_USER')}` }
                }).then((response) => {
                    if (response?.data?.status === true) {
                        setContact(response?.data?.data)
                        // toast.success(response?.data?.message);
                        setContact({ name: "", email: "", number: "", message: "" })
                        setShow(true);
                        setTimeout(() => {
                            handleClose();
                        }, 4000);

                    } else {
                        toast.error(response?.data?.message)
                    }
                }).catch((err) => {
                    console.log('Errors', err);
                    toast.error('Something went wrong...!');
                    if (err.response.status === 401) {
                        localStorage.removeItem('OM_TOKEN_USER')
                        navigate('/login')
                    }
                })
            }
        }

    }

    const validation = () => {
        let isValid = true;
        let err = {};

        if (!contact.name) {
            isValid = false;
            err['name_err'] = "Please enter name"
        }
        if (!contact.email) {
            isValid = false;
            err['email_err'] = "Please enter email"
        } else if (typeof contact.email !== "undefined") {
            let lastAtPos = contact.email.lastIndexOf('@');
            let lastDotPos = contact.email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && contact.email.indexOf('@@') === -1 && lastDotPos > 2 && (contact.email.length - lastDotPos) > 2)) {
                isValid = false;
                err["email_err"] = "Email is not valid";
            }
        }

        if (!contact.number) {
            isValid = false;
            console.log(contact.number, "number")
            err['number_err'] = "Please enter mobile number"
        } else {
            const phoneRegex = /^\d{1,2}-\d{10}$/; // Updated regular expression for phone number validation
            if (!phoneRegex.test(contact.number)) {
                isValid = false;
                err['number_err'] = "Please enter a valid mobile number";
            }
        }

        if (!contact.message) {
            isValid = false;
            err['message_err'] = "Please enter message"
        }
        setError(err);
        return isValid;
    }



    return (
        <div>
            <Toaster />
            <Header />
            {/* <div className="rd-dashboard-content"> */}
            <main className="rd-site-content">
                <div className="rd-site-content__inner">
                    {/* <div className="rd-page-content"> */}
                    <div className="rd-add-customer-content">
                        <form>
                            <div className="row">
                                <div className="col col-12 col-lg-7 mx-auto">
                                    <div className="rd-content-area rd-invite-user">
                                        <h1 className="rd-login-form-title">Contact Us</h1>
                                        <h1 className='rd-login-form-title contact-us'><b>Note</b>: We can also be reached at <a href='mailto:hello@omathomeapp.com'>hello@omathomeapp.com</a></h1>
                                        <h1 className='rd-login-form-title contact-us'>
                                            <b>Address</b>: <br /> <a href='https://goo.gl/maps/98YEomR9qNdgC1dK8' style={{ color: "black" }} target='_blank'>Om Worldwide Inc <br />6538 Collins Ave #344,<br /> Miami Beach, FL 33141</a> 
                                            </h1>

                                        <div className="row">
                                            <div className="col col-12">
                                                <label htmlFor="inputUsername" className="form-label">Name</label>
                                                <input type="text" className="form-control" name='name' value={contact.name} id="inputUsername" onChange={handleChange} />
                                                <span className='error'>{error.name_err}</span>
                                            </div>
                                            <div className="col col-12">
                                                <label htmlFor="inputUsername" className="form-label">Email</label>
                                                <input type="email" className="form-control" name='email' value={contact.email} id="inputUsername" onChange={handleChange} />
                                                <span className='error'>{error.email_err}</span>
                                            </div>
                                            <div className="col col-12 contact-us-number">
                                                <label htmlFor="inputUsername" className="form-label">Mobile Number</label>
                                                <PhoneInput
                                                    inputExtraProps={{
                                                        name: "number",
                                                        required: true,
                                                        autoFocus: true
                                                    }}
                                                    placeholder=""
                                                    name="number"
                                                    defaultCountry={"us"}
                                                    value={contact.number}
                                                    onChange={(val, countryData) => {
                                                        const numericValue = val.replace(/[^0-9]/g, ""); // Extract numeric value only
                                                        const countryCodePrefix = "+" + countryData.dialCode; // Get country code prefix from countryData

                                                        let formattedValue = numericValue;
                                                        if (numericValue.startsWith(countryData.dialCode)) {
                                                            formattedValue = `${countryData.dialCode}-${numericValue.substring(countryData.dialCode.length)}`;
                                                        }
                                                        console.log(formattedValue)
                                                        setContact({ ...contact, number: formattedValue });
                                                    }}
                                                />
                                                <span className='error'>{error.number_err}</span>
                                            </div>

                                            <div className="col col-12">
                                                <label className="form-label">Message</label>
                                                <textarea className={error.message_err ? "form-control error" : "form-control"} cols="30" rows="3" name="message" onChange={handleChange} value={contact.message}></textarea>
                                                <span className='error'>{error.message_err}</span>
                                            </div>
                                        </div>
                                        <div className="rd-heading-btn rd-invite-btn">
                                            <button type='button' onClick={handleSend} className="btn btn-dark">Send</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    {/* </div> */}
                </div>
            </main>
            {/* </div> */}

            <Footer />

            {/* modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Body className='contact-us-modal'>
                    <div>
                        <img src='assets/images/contact_modal.jpg' alt="" className="img-fluid conatct-us-img" />
                        <div style={{ textAlign: "center" }}>
                            <h1 className='contact-heading'>Congratulations</h1>
                            <p className='contact-text'>Your form submitted successful</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ContactUs