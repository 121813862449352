import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast, Toaster } from 'react-hot-toast';
import { OM_API_URL } from '../BaseURL';
import parse from 'html-react-parser';


const PrivacyPolicy = () => {

    useEffect(() => {
        getCmsList()
    }, [])

    const [about, setAbout] = useState('')

    const getCmsList = () => {
        const myurl = `${OM_API_URL}/customer/get-cms`;

        axios({
            method: "post",
            url: myurl,
            // data: fd,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
            .then(async (response) => {
                console?.log("@@", response?.data);
                if (response?.data?.status) {

                    setAbout(response?.data?.data?.user_cms?.privacy_policy)
                } else {
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
                console?.log("Errors", error);
            });
    };


    return (
        <div className='container' style={{ fontFamily: "Source sans pro" }}>
            {/* <div className='mt-3'>
                <div style={{ color: "#969AA3" }}>
                    <p>   This Privacy Policy explains how OM Worldwide Inc. collects, uses, and discloses information about you when use our websites,
                        mobile applications, contact our customer service team, engage with us on social media,
                        or otherwise interact with us and use our products and services (collectively, the “Services”).
                        If we make changes to this Privacy Policy, we will notify you by revising the date at the top of the policy.
                        When you use our Services, we collect the following information about you:
                    </p>

                    <ul>
                        <li>Whenever you use our Services, we collect usage information, such as the classes you use,
                            videos or audio you view or you listen to, messages you write, what screens or features you access,
                            and other usage information.
                        </li>
                        <li>
                            When you make a purchase or return, we collect information about the transaction,
                            such as product description, price, subscription or free trial expiration date,
                            and time and date of the transaction.
                        </li>
                        <li>
                            Like most platforms, we use your IP address to derive the approximate location of your device.
                            We also use your first name to make an educated guess about your gender and use information about your activity
                            to help determine the likelihood of you continuing to use our Services in the future.
                        </li>
                        <li>
                            Log Information: We collect standard log files when you use our Services, which include the type of web browser you use,
                            app version, access times and dates, pages viewed, your IP address, and the page you visited before navigating to our websites.
                        </li>
                        <li>
                            Device Information: We collect information about the computer or mobile device you use to access our Services,
                            including the hardware model, operating system and version, device identifiers set by your device operating system,
                            and mobile network information (like your connection type, carrier and region).
                        </li>
                        <li>
                            Audio, visual or text data, including your profile picture or audio,
                            video or text participation in the Services (for example, where your camera is enabled in video conference sessions that are recorded for later viewing).
                            We may record live meditation classes including the public message board to help us improve our service.
                        </li>
                    </ul>

                    <p> We may use your information for business including providing our services,
                        such as to maintain accounts, provide customer service, process orders and transactions,
                        and verify customer information. Improving and maintaining our Services, such as improving our Services
                        and developing new products and services. Debugging, such as to identify and repair errors and other functionality issues.  </p>
                    <p>
                        Communicate with you about marketing and other relationship or transactional messages.
                        Analyze usage, such as by monitoring trends and activities in connection with use of our Services.
                        Personalize your online experience , such as by tailoring the content and ads you see on our Services
                        and on other platforms based on your preferences, interests, and browsing behavior. Legal reasons,
                        such as to help detect and protect against security incidents, or other malicious, deceptive, fraudulent, or illegal activity.
                    </p>

                    <p>
                        We use the information we collect to:
                    </p>

                    <ul>
                        <li>
                            Communicate with you about products, services, and events offered by OM At Home and others,
                            request feedback, and send news, gifts or other information we think will be of interest to you.
                        </li>
                        <li>
                            Personalize your online experience and the advertisements you see on other platforms based on your preferences,
                            interests, and browsing behavior; and
                        </li>
                        <li>
                            Facilitate contests, sweepstakes, and promotions and process and deliver entries and rewards.
                        </li>
                        <li>
                            Provide, maintain and improve our Services, including debugging to identify and repair errors,
                            and developing new products and services;
                        </li>
                        <li>
                            Process transactions and fulfill orders;
                        </li>
                        <li>
                            Send you transactional or relationship messages, such as receipts,
                            account notifications, customer service responses, and other administrative messages;
                        </li>
                        <li>
                            Monitor and analyze trends, usage, and user activities in connection with our Services;

                        </li>
                        <li>
                            Detect, investigate and prevent fraudulent transactions and other illegal activities and protect the rights and property of OM At Home and others,
                            including to enforce our agreements and policies.
                        </li>
                        <li>
                            Comply with the law, such as by processing transactional records for tax filings and other compliance activities;
                        </li>
                        <li>
                            Share with other third parties to whom you explicitly ask us to send your information (or about whom you are otherwise explicitly notified and
                            solicited consent when using a specific service), this includes when you share content to Instagram;
                        </li>
                    </ul>
                    <p>
                        We collect information when you register for an account, fill out a form or a survey, participate in a promotion,
                        make a purchase, communicate with us via social media sites, request customer support, or otherwise communicate with us.
                        We also collect the content of any messages you post publicly, including messages, reactions and comments during live
                        streamed classes. The information you may provide includes your name, email, password, street address, payment method
                        information, feedback and information you may provide on message boards or other communication. In order to offer the
                        services for which you will use the App or Services, your first name, last name, profile picture, bio, and location may
                        necessarily be displayed throughout the Services and to the public.
                    </p>
                    <p>
                        If you wish to disable your account, please note that we retain certain information when required or permitted by law.
                        We also retain cached or archived copies of information about you for a certain period of time.
                    </p>
                    <p>
                        We also obtain information about you from other sources, including transaction information from third-party app stores
                        you use to install our app or purchase a subscription, and name and contact information from third-party calendar services.
                        Additionally, if you create or log into your account through a social media service account, we will have access to certain
                        information from that account, such as your name and other account information, in accordance with the authorization
                        procedures set by that social media service. Finally, we may obtain information about you from publicly available sources,
                        marketing and advertising partners, consumer research platforms, and/or business contact databases.
                    </p>
                    <p>
                        We share information about you as follows and as otherwise described in this Privacy Policy or at the time of collection:
                    </p>
                    <ul>
                        <li>
                            With companies and contractors that perform services for us, including email service providers,
                            payment processors, fraud prevention vendors and other service providers;
                        </li>
                        <li>
                            In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law or legal process, including court order,
                            subpoena, or other lawful requests by public authorities to meet national security or law enforcement requirements;
                        </li>
                        <li>
                            If we believe your actions are inconsistent with our user agreements or policies,
                            if we believe you have violated the law, or to protect the rights, property, and safety of OM At Home or others;
                        </li>
                        <li>
                            In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of
                            all or a portion of our business by another company;
                        </li>
                        <li>
                            Between and among OM At Home and our current and future parents, affiliates, subsidiaries,
                            and other companies under common control and ownership; and
                        </li>
                        <li>
                            You may choose to share actions you take on our Services with third-party social media services via
                            the integrated tools we provide via our Services.
                        </li>
                    </ul>
                    <p>
                        We also share aggregated or other information not subject to obligations under the data protection
                        laws of your jurisdiction with third parties. For example, we sometimes share aggregate information
                        with research organizations to help facilitate their research.
                    </p>
                    <p>
                        We allow others to provide analytics services and serve advertisements on our behalf across the web
                        and in mobile applications. These entities use cookies, web beacons, device identifiers and other technologies
                        to collect information about your use of the Services and other websites and online services, including your IP address,
                        device identifiers, web browser, mobile network information, pages viewed, time spent on pages or in apps, links clicked,
                        and conversion information. This information may be used by OM At Home and others to, among other things, analyze and track
                        data, determine the popularity of certain content, deliver advertising and content targeted to your interests on our Services
                        and other websites and online services, and better understand your online activity.
                    </p>
                    <p>
                        You may opt out of receiving promotional emails from OM At Home by following the instructions in those emails or
                        by emailing support@OMatHome.co. If you opt out, we may still send you non-promotional emails, such as those about your
                        account or our ongoing business relations.
                    </p>
                    <p>
                        With your consent, we send promotional and other push notifications to your mobile device. You can deactivate these
                        messages at any time by changing the notification settings on your mobile device.
                    </p>
                    <p>
                        Information for Individuals in Switzerland, UK and the EEA
                    </p>
                    <p>
                        When we process your personal data we will only do so in the following situations:
                    </p>
                    <ul>
                        <li>
                            As necessary to perform our responsibilities under our contract with you
                            (like processing payments and providing the products or services you have requested);
                        </li>
                        <li>
                            When we have a legitimate interest in processing your personal data, including to communicate with
                            you about changes to our Services, to help secure and improve our Services (including to prevent fraud)
                            and to analyze use of our Services;
                        </li>
                        <li>
                            As necessary to comply with our legal obligations; and
                        </li>
                        <li>
                            When we have your consent to do so.
                        </li>
                    </ul>
                    <p>
                        Subject to certain limits and conditions provided under law, when we ask for your consent,
                        you may withdraw that consent at any time.
                    </p>
                    <p>
                        Information for California Residents
                    </p>
                    <p>
                        This section provides additional disclosures required by the California Consumer Privacy Act (or “CCPA”).
                    </p>
                    <p>
                        We collect Identifiers such as your name, phone number, email address, social media handle, and unique identifiers
                        (like IP address) tied to your browser or device. Characteristics of protected classifications under state or federal law ,
                        such as gender and age. Commercial information, such as your payment information and OM At Home product or service purchases.
                        Approximate geolocation data. Internet or other electronic network activity, such as browsing behavior and information about
                        your usage and interactions with our Services. Audio, electronic, visual, or similar information, such as profile photo or
                        personal information or opinions you may provide during any communication with OM At Home.
                    </p>
                    <p>
                        Companies that provide services to us , such as those that assist us with customer support, subscription and order fulfillment,
                        advertising measurement, communications and surveys, data analytics, fraud prevention, cloud storage, bug fix management and
                        logging, and payment processing. Third parties with whom you consent to sharing your information , such as with social media
                        services or academic researchers.
                    </p>
                    <p>
                        Subject to certain limitations and exceptions, the CCPA provides California consumers the right to request to know more details
                        about the categories and specific pieces of personal information, to delete their personal information, to opt out of any
                        “sales” that may be occurring, If you are a California resident, you have the right to opt-out of the sharing of your personal
                        information by us to third parties who are not our Service Providers at any time (in accordance with the California Consumer
                        Privacy Act). You may submit a request to opt-out by emailing support@OMatHome.co
                    </p>
                    <p>
                        We allow our advertising partners to collect certain device identifiers and electronic network activity via our
                        Services to show ads that are targeted to your interests on other platforms.
                    </p>
                    <p>
                        We may offer various financial incentives. For example, we may provide incentives to customers who participate
                        in a survey or provide testimonials. When you participate in a financial incentive, we collect personal information from you,
                        such as identifiers (like your name and email address) and information about your experiences using our Services.
                    </p>
                    <p>
                        OM At Home is based in the United States and processes information in the United States, which may not provide
                        equivalent levels of data protection as your home jurisdiction.
                    </p>
                    <p>
                        If you have any questions about this Privacy Policy, please contact us at: <a href='mailto:hello@OMatHome.co'>hello@OMatHome.co</a>
                    </p>
                </div>
            </div> */}
            {
                <div className='mt-3'>
                    {parse(about)}
                </div>
            }
        </div>
    )
}

export default PrivacyPolicy