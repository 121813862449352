import React from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'

const BlockDetails = () => {

    const location = useLocation();
    const data = location.state

    const [blog, setBlog] = useState({
        title: location?.state?.title,
        description: location?.state?.description,
        image: location?.state?.image
    })

    console.log(data, "data")

    return (
        <div>
            <Header />
            <section className="blog-detail-section">
                <div className="container">
                    <div className="blog-detail-content">
                        <div className="row">
                            <div className="col col-12 col-md-6 col-lg-6">
                                <div className="blog-detail-img">
                                    <img src={blog?.image} alt="blog-detail-img"
                                        className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="col col-12 col-md-6 col-lg-6">
                                <div className="blog-detail-text">
                                    <h4 className="blog-detail-title">{blog.title}</h4>
                                    <div className="blog-detail-description">
                                        <p>{blog.description}</p>
                                        {/* <p>Growing up in certain neighborhoods in the United States can have significant
                                            effects on a person's mental health. In fact, studies have shown that children
                                            who grow up in these neighborhoods can experience similar levels of trauma and
                                            stress as children who grow up in war-torn countries like Iraq.</p>

                                        <p>For example, a study conducted by the American Psychological Association found
                                            that African-American children who grow up in neighborhoods with high levels of
                                            violence are more likely to develop PTSD than soldiers returning from war zones.
                                            In addition, research has found that growing up in a neighborhood with high
                                            levels of violence can lead to decreased academic achievement, increased
                                            aggression, and even physical health problems. Children who grow up in certain
                                            zip codes may experience feelings of fear and hyper-vigilance, similar to those
                                            experienced by soldiers in combat. They may also constantly feel on edge and be
                                            hypervigilant to potential threats, even when they are not present, flashbacks,
                                            nightmares, and avoidance behaviors.</p>

                                        <p>In this context, finding refuge at home can be crucial. One way to calm the
                                            nervous system and reduce stress is through mindfulness practices like
                                            meditation. However, due to stigma and lack of resources, it can be challenging
                                            for children to access these types of interventions.</p>

                                        <p>The OM at Home app can be a helpful tool for individuals looking to incorporate
                                            mindfulness practices into their daily routine. The app provides a community of
                                            like-minded individuals who are also practicing mindfulness. Users can connect
                                            with Growing up in certain U.S. neighborhoods can have significant effects on a
                                            person's mental health. However, by increasing access to mental health services,
                                            addressing systemic issues, providing resources for coping, creating safe
                                            spaces, and addressing gun violence, we can start to address this issue and
                                            support individuals in these communities.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default BlockDetails