import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { OM_API_URL } from '../BaseURL';
import { gapi } from 'gapi-script';
import { toast, Toaster } from 'react-hot-toast';
import Footer from './Footer';
import Header from './Header';

const Group = () => {
    useEffect(() => {
        getSubscriptionPlan();
        getPlan();
        getHomeData();
    }, []);

    const [plans, setPlans] = useState([]);
    const [plansTwo, setPlansTwo] = useState([]);

    const [planDetails, setPlanDetails] = useState([])
    const [currentPlanId, setCurrentPlanId] = useState([]);

    const [home, setHome] = useState({ title: "", sub_title: "", image: "" });

    const navigate = useNavigate();

    const getHomeData = () => {
        axios({
            method: 'get',
            url: `${OM_API_URL}/customer/get-homes`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then((response) => {
            console.log('@@$$', response?.data)
            if (response.data.status) {
                let data = response?.data?.data
                setHome({
                    title: data?.title,
                    sub_title: data?.sub_title,
                    image: data?.image
                })
            }
        }).catch((error) => {
            console.log(error, "error")
        })
    }


    const getSubscriptionPlan = () => {
        axios({
            method: 'post',
            url: `${OM_API_URL}/customer/get-subscription-plans`,
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('OM_TOKEN_USER')}` }
        }).then(async (response) => {
            if (response.data.status === true) {
                // console.log(response.data.data.plans)
                let data = await Promise.all(response.data.data.plans.map(elem => {
                    return { ...elem, description: elem.description.split('\n') }
                }))
                setPlans(data)
                if (response.data.data.active_plan !== '') {
                    const detail = response.data.data.active_plan
                    setPlanDetails(detail)
                    setCurrentPlanId(detail._id)
                }
            }
        }).catch((err) => {
            console.log(err)
            if (err.response.status === 401) {
                localStorage.removeItem('OM_TOKEN_USER')
                navigate('/login')
            }
        })
    }

    const getPlan = () => {
        axios({
            method: 'get',
            url: `${OM_API_URL}/web/get-subscription-plans`,
            headers: { 'Content-Type': 'application/json' }
        }).then(async (response) => {
            if (response.data.status === true) {
                let data = await Promise.all(response.data.data.map(elem => {
                    return { ...elem, description: elem.description.split('\n') }
                }))
                setPlansTwo(data)
            }
        }).catch((err) => {
            console.log(err, "error")
            if (err.response.status === 401) {
                localStorage.removeItem('OM_TOKEN_USER')
                navigate('/login')
            }
        })
    }

    const handleUserSubscription = (id) => {
        if (localStorage.getItem('OM_TOKEN_USER') === null) {
            navigate('/sign-up')
        } else {
            navigate('/payment', { state: id })
        }
    }

    const checkPlan = (id) => {
        let isVlaid = false;
        if (plans.filter(pl => pl._id === id)[0].amount === 0 && currentPlanId !== id) { isVlaid = true } else { isVlaid = false }
        return isVlaid
    }


    const handleUserSubscriptionFree = (id) => {
        if (localStorage.getItem('OM_TOKEN_USER') === null) {
            navigate('/sign-up')
        }
        else {
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('subscription_plan_id', id);
            axios({
                method: 'post',
                url: `${OM_API_URL}/customer/start-subscription`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded ', 'Authorization': `Bearer ${localStorage.getItem('OM_TOKEN_USER')}` }
            }).then((response) => {
                if (response.data?.status === true) {
                    // toast.success(response.data?.message, { style: { background: '#333', color: '#fff' } });
                    toast.success(" Congratulations you’re subscribed", { style: { background: '#333', color: '#fff' } });
                    setTimeout(() => {
                        navigate('/');
                    }, [1000])
                    console.log("data subscribe")
                }
                else {
                    console.log("data subscribe not subscribe")
                    // toast.error(response.data.message, { style: { background: '#333', color: '#fff' } })
                }
            }).catch((err) => {
                toast.error(err.response.data.message, { style: { background: '#333', color: '#fff' } });
            })
        }


    }
    const handleBusiness = () => {
        window.location.href = "https://group.omathomeapp.com/login";
    }
    const handleNav = (val) => {
        navigate(val)
        window.scrollTo(0, 0)
    }

    return (
        <div>
            <Toaster />
            <Header currentPage="group" />

            <div>
                <section class="om-landing-section om-group-landing-section">
                    <div class="om-landing-content">
                        <img src="assets\images\home-landing-img.jpg" alt="home-landing-img" class="img-fluid w-100" />
                        <div class="om-landing-content__inner">
                            <div class="om-landing-content-text">
                                <div class="container">
                                    <h1 class="section-title">Om At Home for Groups</h1>
                                    <div class="text-center om-landing-clases-btn">
                                        <a  onClick={handleBusiness} class="btn btn-light">Group Sign Up</a>
                                        <a  onClick={handleBusiness} class="btn btn-outline-light">Group Sign In</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="subscription-section group-small-heading" id="subscription">
                    <div class="container">
                        <div class="group-subscription-content">
                            <div class="subscription-content">
                                <h2 class="section-title">How Group Subscriptions Work </h2>

                                <p class="section-description"><span>01</span> <span>Sign up as a group and choose a
                                    subscription</span></p>
                                <div class="group-subscription-content__inner">
                                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="pills-individual-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-individual" type="button" role="tab"
                                                aria-controls="pills-individual" aria-selected="true">Individual</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="pills-business-tab" data-bs-toggle="pill"
                                                data-bs-target="#pills-business" type="button" role="tab"
                                                aria-controls="pills-business" aria-selected="false">Group</button>
                                        </li>
                                    </ul>
                                    <div class="tab-content subscription-tab-content" id="pills-tabContent">
                                        <div class="tab-pane fade show active" id="pills-individual" role="tabpanel"
                                            aria-labelledby="pills-individual-tab">
                                            <div className="row">
                                                {plans.length > 0 && plans.map((elem, index) =>
                                                    <div className="col col-4 col-md-6 col-lg-4">
                                                        <div className={`subscription-plans ${index === 1 ? 'active' : ''}`} key={elem.id}>
                                                            <div className="subscription-plans-details">
                                                                <h4 className="subscription-title">{elem.plan_name}</h4>

                                                                {index === 1 ? (
                                                                    <h3 className="subscription-price free">
                                                                        $ {elem.amount}
                                                                        {/* <p className='subscription-price-plan'> $6.58/ month </p> */}
                                                                    </h3>
                                                                ) : (
                                                                    <h3 className="subscription-price">
                                                                        $ {elem.amount}
                                                                    </h3>
                                                                )}
                                                                {elem.description.map(e =>
                                                                    <ul className="subscription-plans-feature">
                                                                        <li>{e.split('. ')[1]}</li>
                                                                    </ul>
                                                                )}
                                                            </div>
                                                            <div className="subscription-plans-footer">
                                                                {elem.amount === 0 ? (
                                                                    <button type="button" onClick={() => handleUserSubscriptionFree(elem._id)}
                                                                        disabled={currentPlanId ? checkPlan(elem._id) : false} className="btn btn-subscription">
                                                                        {elem._id === currentPlanId ? 'Current Plan - Try For Free' : 'Try For Free'}
                                                                    </button>
                                                                ) : (
                                                                    <button type="button" onClick={() => handleUserSubscription(elem._id)}

                                                                        disabled={(elem._id === currentPlanId) || (planDetails.duration === 'Yearly plan')}
                                                                        className="btn btn-subscription">
                                                                        {elem._id === currentPlanId ? 'Current Plan' : 'Subscribe'}
                                                                    </button>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-business" role="tabpanel"
                                            aria-labelledby="pills-business-tab">
                                            <div className="row">
                                                {plansTwo.length > 0 && plansTwo.map((element, index) =>
                                                    <div className="col col-4 col-md-6 col-lg-4">
                                                        <div className={`subscription-plans ${index === 1 ? 'active' : ''}`} key={element.id}>

                                                            <div className="subscription-plans-details">
                                                                <h4 className="subscription-title">{element.plan_name}</h4>

                                                                {index === 0 ? (
                                                                    <h3 className="subscription-price free">
                                                                        $ {element.amount}
                                                                        {/* <p className='subscription-price-plan-details'> $1/month per user </p> */}
                                                                    </h3>
                                                                ) : (
                                                                    <h3 className="subscription-price">
                                                                        $ {element.amount}
                                                                        {/* <p className='subscription-price-plan-details'>$6.58/month</p> */}
                                                                    </h3>
                                                                )}

                                                                {element.description.map(e =>
                                                                    <ul className="subscription-plans-feature">
                                                                        <li>{e.split('. ')[1]}</li>
                                                                    </ul>
                                                                )}
                                                            </div>
                                                            <div className="subscription-plans-footer">
                                                                <button type="button" onClick={handleBusiness} className="btn btn-subscription">Subscribe</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="group-subscription-content">
                            <p class="section-description"><span>02</span> <span>Get access to a dashboard and input
                                emails to add users</span></p>
                            <div class="access-img-content">
                                <img src="assets/images/access-img.jpg" alt="Access" class="img-fluid" />
                            </div>
                        </div>

                        <div class="group-subscription-content">
                            <p class="section-description"><span>03</span> <span>Users will receive an email with a
                                password to enjoy full <br />access for the duration of the group subscription</span></p>
                            <div class="receive-img-content">
                                <img src="assets/images/receive-img.jpg" alt="receive-img" class="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>

            </div >
            <Footer />

        </div >
    )
}

export default Group
