import axios from 'axios';
import React, { useState } from 'react'
import { toast, Toaster } from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { OM_API_URL } from '../BaseURL';


const ResetPassword = () => {
    const [details, setDetails] = useState({ password: '', confirm_password: '', pwdShow: true, cpwdShow: true });
    const [error, setError] = useState({});
    const nav = useNavigate();
    const location = useLocation();
    const email = location.state;

    const handleChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value })
    }

    const validation = () => {
        let isValid = true;
        let err = {};

        if (!details.password) {
            isValid = false;
            err['password_err'] = "Please enter password"
        }

        if (!details.confirm_password) {
            isValid = false;
            err['confirm_password_err'] = "Please enter confirm password"
        }

        if (details.password && details.confirm_password) {
            if (details.password.toLowerCase() !== details.confirm_password.toLowerCase()) {
                isValid = false;
                err['confirm_password_err'] = "Password doesn't match";
            }
        } else {
            console.log()
        }

        setError(err);
        return isValid
    }

    const handleChangePassword = (e) => {
        e.preventDefault();
        if (validation()) {
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('email', email);
            bodyFormData.append('newpassword', details.password);
            axios({
                method: 'post',
                url: `${OM_API_URL}/customer/reset-password`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                if (response.data.status === true) {
                    setTimeout(() => {
                        nav('/login')
                    }, 1500)
                    toast.success("Password Changed Successfully...")
                }
            }).catch((err) => {
                setError({ ...error, confirm_password_err: err.response.data.message })
                toast.error(err.response.data.message)
            })
        }
    }
    return (
        <div>
            <Toaster />
            <main className="rd-site-content">
                <section className="rd-login-section">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-6">
                            <div className="rd-login-content">
                                <a href="/login" className="rd-login-logo">
                                    <span className="rd-login-logo__img">
                                        {/* <img src="assets/images/om_logo.svg" alt="logo" className="img-fluid" style={{ height: "100px", width: "auto" }} /> */}
                                        <img src="assets/images/logo.svg" alt="logo" className="img-fluid" style={{ height: "100px", width: "auto" }} />
                                    
                                    </span>
                                </a>
                                <div className="rd-login-form-content" data-scrollbar style={{ overflow: "auto" }}>
                                    <div className="rd-login-form-heading">
                                        <h1 className="rd-login-form-title">Reset password</h1>
                                        <p className="rd-login-form-description">Enter New Password for login</p>
                                    </div>
                                    <div className="rd-login-form">
                                        <form className="row g-0" autocomplete="off">
                                            <div className="col-md-12">
                                                <label htmlFor="inputUsername" className="form-label">New Password</label>
                                                <div className='password-field'>
                                                    <input type={details.pwdShow ? "password" : "text"} className={error.password_err ? "form-control error" : "form-control"} id="inputUsername" name="password" onChange={handleChange} value={details.password} />
                                                    {details.pwdShow ? <i className='fa fa-eye' onClick={() => { setDetails({ ...details, pwdShow: false }) }} /> : <i className='fa fa-eye-slash' onClick={() => setDetails({ ...details, pwdShow: true })} />}
                                                </div>
                                                <span className='error'>{error.password_err}</span>
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="inputPassword4" className="form-label">Reenter new Password</label>
                                                <div className='password-field'>
                                                    <input type={details.cpwdShow ? "password" : "text"} className={error.confirm_password_err ? "form-control error" : "form-control"} id="inputPassword4" name="confirm_password" onChange={handleChange} value={details.confirm_password} />
                                                    {details.cpwdShow ? <i className='fa fa-eye' onClick={() => { setDetails({ ...details, cpwdShow: false }) }} /> : <i className='fa fa-eye-slash' onClick={() => setDetails({ ...details, cpwdShow: true })} />}
                                                </div>
                                                <span className='error'>{error.confirm_password_err}</span>
                                            </div>
                                            <div className="col-12">
                                                <button type="button" onClick={handleChangePassword} className="btn btn-dark login-btn w-100">Change Password</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <p className="rd-site-reserved d-none d-lg-block">All rights reserved. © 2023 Om Outline</p>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-6">
                            {window.innerWidth > 767 ?

                                <div className="rd-login-img">
                                    <img src="assets/images/login_img.jpg" alt="login-img" className="img-fluid w-100" />
                                </div>
                                : ""}
                        </div>
                    </div>
                </section>
            </main>

        </div>
    )
}

export default ResetPassword