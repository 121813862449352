import axios from 'axios';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { OM_API_URL } from '../BaseURL';

const ForgotPassword = () => {

    const [email, setEmail] = useState('');
    const [err, setErr] = useState('');
    const nav = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (email) {
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('email', email);
            axios({
                method: 'post',
                url: `${OM_API_URL}/customer/otp-resend`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
            }).then((response) => {
                console.log(response.data);
                if (response.data.status === true) {
                    // setMinutes(1);
                    // setSeconds(59);
                    // setErr('');
                    nav('/otp-verification', { state: { email: email, type: 'forgot' } })
                }
            }).catch((err) => {
                setErr(err.response.data.message);
                if (err.response.status === 401) {
                    localStorage.removeItem('OM_TOKEN_USER')
                    nav('/login')
                }
            })
        } else {
            setErr('Please enter email')
        }
    }

    return (
        <div>
            <main className="rd-site-content">
                <section className="rd-login-section">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-6">
                            <div className="rd-login-content om-otp-verificarion-content">
                                <Link to="#" className="rd-login-logo">
                                    <span className="rd-login-logo__img">
                                        <img src="assets/images/logo.svg" alt="logo" className="img-fluid" style={{ height: "100px", width: "auto" }} />
                                    </span>
                                </Link>
                                <div className="rd-login-form-content">
                                    <div className="rd-login-form-heading">
                                        <h1 className="rd-login-form-title">Forget Password</h1>

                                    </div>
                                    <div className="rd-login-form">
                                        <form className="row" autocomplete="off">
                                            <div className="col-md-12">
                                                <label htmlFor="inputEmail" className="form-label">Email</label>
                                                <input type="text" className={err ? "form-control error" : "form-control"} id="inputEmail" onChange={(e) => setEmail(e.target.value)} />
                                                <span htmlFor="inputEmail" className="error">{err}</span>
                                            </div>
                                            <div className="col-12">
                                                <button type="button" onClick={handleSubmit} className="btn btn-dark login-btn w-100">Send OTP</button>
                                            </div>
                                            <div className="col-12">
                                                <div className="text-center">
                                                    <Link to="/login"><p className="login-notes">Back To Login</p></Link>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <p className="rd-site-reserved d-none d-lg-block">All rights reserved. © 2023 Om Outline</p>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-6">
                            {window.innerWidth > 767 ?

                                <div className="rd-login-img">
                                    <img src="assets/images/login_img.jpg" alt="login-img" className="img-fluid w-100" />
                                </div>
                                : ""}
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default ForgotPassword