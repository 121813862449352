import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { OM_API_URL } from '../BaseURL';
import { useGoogleLogin, useGoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';
import { toast, Toaster } from 'react-hot-toast';
import AppleLogin from 'react-apple-login';

const Login = () => {

    let navigate = useNavigate()

    useEffect(() => {
        // if (localStorage.getItem('OM_TOKEN_USER') === null) {
        //     navigate('/sign-up')
        // }
        // else {
        //     navigate("/")
        // }
    }, [])

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Update window width on resize
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [details, setDetails] = useState({ email: "", password: "", pwdShow: true });
    const [error, setError] = useState({});
    const nav = useNavigate();
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const handleChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value })
    }

    const validation = () => {
        var isValid = true;
        let err = {};

        if (!details.email) {
            isValid = false;
            err['email_err'] = "Please enter email"
        } else if (typeof details.email !== "undefined") {
            let lastAtPos = details.email.lastIndexOf('@');
            let lastDotPos = details.email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && details.email.indexOf('@@') === -1 && lastDotPos > 2 && (details.email.length - lastDotPos) > 2)) {
                isValid = false;
                err["email_err"] = "Email is not valid";
            }
        }

        if (!details.password) {
            isValid = false;
            err['password_err'] = "Please enter your password"
        }

        setError(err);
        return isValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validation()) {
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('email', details.email);
            bodyFormData.append('password', details.password);
            bodyFormData.append('fcm_token', '1');
            bodyFormData.append('login_type', '1');
            bodyFormData.append('timezone', userTimezone);

            axios({
                method: 'post',
                url: `${OM_API_URL}/customer/sign-in`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
            }).then((response) => {
                console.log(response.data);
                if (response.data.verified === false) {
                    nav('/otp-verification', { state: { email: details.email, type: 'login' } })
                } else {
                    if (response.data.data[0].is_subscription === true) {
                        // toast.success("Subscription plan is already running"  ,{ autoClose: 4000 })
                        setTimeout(() => {
                            nav('/');
                            window.location.reload();
                        }, 1500)
                        toast.success("Login Successful")
                    } else {
                        setTimeout(() => {
                            nav('/subscription-plan')
                        }, 1500)
                        toast.success("Login Successful")
                    }
                    localStorage.setItem('OM_TOKEN_USER', response.data.data[0].token);
                }
            }).catch((err) => {
                setError({ ...error, password_err: err.response.data.message })
                toast.error(err.response.data.message)
            })
        }
    }


    const { signOut } = useGoogleLogout({
        clientId: "1047790433688-bm05f0ehoghcus8o8qn7el61ogdm46u3.apps.googleusercontent.com"
    })


    const onFailure = (res) => {
        console.log('Sign-in failed', res);
        signOut();
    }

    const responseGoogle = (googleUser) => {
        // console.log('Google Sign-In successful');
        // console.log('Google user information:', googleUser);

        const bodyFormData = new URLSearchParams();
        bodyFormData.append('email', googleUser.profileObj.email);
        // bodyFormData.append('password', '');
        bodyFormData.append('fcm_token', '1');
        bodyFormData.append('login_type', '3');
        bodyFormData.append('social_id', googleUser.profileObj.googleId);
        bodyFormData.append('timezone', userTimezone);
        axios({
            method: 'post',
            url: `${OM_API_URL}/customer/sign-in`,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
        }).then((response) => {
            console.log(response.data, "data");
            if (response.data.verified === false) {
                nav('/otp-verification', { state: { email: googleUser.profileObj.email, type: 'login' } })
            } else {
                if (response.data.data[0].is_subscription === true) {
                    // toast.success("Subscription plan is already running"  ,{ autoClose: 4000 })
                    setTimeout(() => {
                        nav('/');
                        window.location.reload();
                    }, 1500)
                    // localStorage.setItem("OM_TOKEN_USER", response?.data?.data?.token)
                } else {
                    nav('/subscription-plan')
                }
            }
            // toast.success("Subscription plan is already running"  ,{ autoClose: 4000 })
            // localStorage.setItem("OM_TOKEN_USER", googleUser.accessToken)
            localStorage.setItem("OM_TOKEN_USER", response?.data?.data[0].token)
            toast.success("Google Login Successful")
        }).catch((err) => {
            setError(err)
            toast.error(err.response.data.message)
        })
        const idToken = googleUser.accessToken;
        // console.log(idToken, "id")
    };



    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: "1047790433688-bm05f0ehoghcus8o8qn7el61ogdm46u3.apps.googleusercontent.com",
                scope: 'email',
            });
        }
        gapi.load('client:auth2', (err) => { callback(err) }, start);
    }, []);

    function callback(err) {
        if (err) {
            console.log(err);
            return;
        }
    }

    const { signIn } = useGoogleLogin({
        onSuccess: responseGoogle,
        onFailure,
        clientId: "1047790433688-bm05f0ehoghcus8o8qn7el61ogdm46u3.apps.googleusercontent.com",
        isSignedIn: 'true',
        prompt: 'select_account'
    })

    const success = (response) => {
        console.log(response)

        const bodyFormData = new URLSearchParams();
        bodyFormData.append('email',);
        // bodyFormData.append('password', '');
        bodyFormData.append('fcm_token', '1');
        bodyFormData.append('login_type', '4');
        bodyFormData.append('social_id',);
        bodyFormData.append('timezone', userTimezone);

        axios({
            method: 'post',
            url: `${OM_API_URL}/customer/sign-in`,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded ' }
        }).then((response) => {
            console.log(response.data);
            // if (response.data.verified === false) {
            // nav('/otp-verification', { state: { email: , type: 'login' } })
            // } else {
            // if (response.data.data[0].is_subscription === true) {
            // toast.success("Subscription plan is already running"  ,{ autoClose: 4000 })
            // setTimeout(() => {
            //     nav('/');
            //     window.location.reload();
            //     localStorage.setItem("OM_TOKEN_USER",)
            // }, 1500)
            //     } else {
            //         nav('/subscription-plan')
            //     }
            // }
            // toast.success("Subscription plan is already running"  ,{ autoClose: 4000 })
            toast.success("Apple Login Successful")
        }).catch((err) => {
            setError(err)
            toast.error(err.response.data.message)
        })

    }

    const fail = (response) => [
        console.log(response)
    ]


    return (
        <div>
            <Toaster />
            <main className="rd-site-content">
                <section className="rd-login-section">
                    <div className="row g-0">
                        <div className="col col-12 col-md-12 col-lg-6">
                            <div className="rd-login-content" >
                                <a href="/" className="rd-login-logo">
                                    <span className="rd-login-logo__img">
                                        <img src="assets/images/logo.svg" alt="logo" className="img-fluid" />
                                    </span>
                                </a>

                                <div className={`rd-login-form-content ${windowWidth > 767 ? 'overflow-auto' : ''}`}  data-scrollbar style={{ overflow: "auto" }}>
                                    <div className="rd-login-form-heading" >
                                        <h1 className="rd-login-form-title">Login</h1>
                                        <p className="rd-login-form-description">Sign into your account</p>
                                    </div>
                                    <div className="rd-login-form">
                                        <form className="row g-0" autocomplete="off">
                                            <div className="col-md-12">
                                                <label htmlFor="inputUsername" className="form-label">Email</label>
                                                <input type="email" className="form-control" id="inputUsername" name='email' onChange={handleChange} />
                                                <span className='error'>{error.email_err}</span>
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="inputPassword4" className="form-label">Password</label>
                                                <div className="password-field">
                                                    <input type={details.pwdShow ? "password" : "text"} id="inputPassword" className={error.password_err ? "form-control error" : "form-control"} name="password" onChange={handleChange} />
                                                    {details.pwdShow ? <i className='fa fa-eye' onClick={() => setDetails({ ...details, pwdShow: false })} /> : <i className='fa fa-eye-slash' onClick={() => setDetails({ ...details, pwdShow: true })} />}
                                                </div>
                                                <span className='error'>{error.password_err}</span>
                                            </div>
                                            <div className="col-12">
                                                <button type="button" onClick={handleSubmit} className="btn btn-dark login-btn w-100">Sign In</button>
                                                <div className="text-end">
                                                    <Link to="/forgot-password" className="rd-forgot-passlink">Forgot
                                                        Password?</Link>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <ul className="login-with-social">
                                                    <li className="login-with-social-item">
                                                        <button type="button" onClick={signIn} className="login-with-social-link">
                                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M26.625 15.2727C26.625 14.4277 26.5542 13.6127 26.4207 12.8328H15.2354V17.4543H21.6419C21.3667 18.9609 20.5349 20.2427 19.2729 21.101L23.0964 24.1026C25.3351 22.0127 26.625 18.9243 26.625 15.2727Z"
                                                                    fill="white" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M15.2362 27.0001C18.437 27.0001 21.132 25.9368 23.0973 24.1034L19.2738 21.1018C18.2096 21.8268 16.839 22.2501 15.2362 22.2501C12.1426 22.2501 9.51669 20.1402 8.57769 17.2969L4.63892 20.3885C6.59098 24.3084 10.6022 27.0001 15.2362 27.0001Z"
                                                                    fill="white" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M8.57726 17.2965C8.33677 16.5715 8.20493 15.7982 8.20493 14.9999C8.20493 14.2016 8.33677 13.4282 8.57726 12.7032L4.63849 9.61157C3.8313 11.2316 3.375 13.0616 3.375 14.9999C3.375 16.9381 3.8313 18.7683 4.63849 20.3881L8.57726 17.2965Z"
                                                                    fill="white" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M15.2362 7.74997C16.984 7.74997 18.5506 8.35822 19.7861 9.54994V9.55163L23.1714 6.12664C21.1156 4.18999 18.4353 3 15.2362 3C10.6022 3 6.59098 5.69164 4.63892 9.61159L8.57773 12.7032C9.51669 9.85991 12.1426 7.74997 15.2362 7.74997Z"
                                                                    fill="white" />
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li className="login-with-social-item">
                                                        {/* <a href="#" className="login-with-social-link">
                                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M24.8432 11.1861C24.7286 11.2529 22.0006 12.6637 22.0006 15.7918C22.1292 19.3591 25.4432 20.6102 25.5 20.6102C25.4432 20.677 24.9997 22.3144 23.686 24.0308C22.6435 25.5093 21.4863 27 19.7292 27C18.0578 27 17.4578 26.0146 15.5292 26.0146C13.458 26.0146 12.872 27 11.2863 27C9.52917 27 8.28631 25.4295 7.18692 23.9648C5.75866 22.0479 4.54467 19.0397 4.50181 16.1512C4.47293 14.6206 4.78786 13.116 5.58721 11.8381C6.71547 10.054 8.72978 8.84285 10.9295 8.80291C12.6149 8.74996 14.1149 9.88122 15.1434 9.88122C16.1292 9.88122 17.972 8.80291 20.0571 8.80291C20.9571 8.80381 23.3571 9.05644 24.8432 11.1861ZM15.0009 8.49732C14.7009 7.09955 15.5292 5.70177 16.3006 4.81017C17.2863 3.73188 18.8432 3 20.1857 3C20.2714 4.39778 19.7283 5.76863 18.7574 6.76703C17.8863 7.84531 16.3863 8.65707 15.0009 8.49732Z"
                                                                    fill="white" />
                                                            </svg>
                                                        </a> */}
                                                        <AppleLogin
                                                            clientId="com.omathomeapp"
                                                            redirectURI="https://omathomeapp.com"
                                                            onSuccess={success}
                                                            onFailure={fail}
                                                            render={renderProps => (
                                                                <button
                                                                    type="button"
                                                                    onClick={renderProps.onClick}
                                                                    className="login-with-social-link"
                                                                >
                                                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M24.8432 11.1861C24.7286 11.2529 22.0006 12.6637 22.0006 15.7918C22.1292 19.3591 25.4432 20.6102 25.5 20.6102C25.4432 20.677 24.9997 22.3144 23.686 24.0308C22.6435 25.5093 21.4863 27 19.7292 27C18.0578 27 17.4578 26.0146 15.5292 26.0146C13.458 26.0146 12.872 27 11.2863 27C9.52917 27 8.28631 25.4295 7.18692 23.9648C5.75866 22.0479 4.54467 19.0397 4.50181 16.1512C4.47293 14.6206 4.78786 13.116 5.58721 11.8381C6.71547 10.054 8.72978 8.84285 10.9295 8.80291C12.6149 8.74996 14.1149 9.88122 15.1434 9.88122C16.1292 9.88122 17.972 8.80291 20.0571 8.80291C20.9571 8.80381 23.3571 9.05644 24.8432 11.1861ZM15.0009 8.49732C14.7009 7.09955 15.5292 5.70177 16.3006 4.81017C17.2863 3.73188 18.8432 3 20.1857 3C20.2714 4.39778 19.7283 5.76863 18.7574 6.76703C17.8863 7.84531 16.3863 8.65707 15.0009 8.49732Z" fill="white" />
                                                                    </svg>
                                                                </button>
                                                            )}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-12">
                                                <div className="text-center">
                                                    <p className="login-notes">Don’t Have an Account ? <Link to="/sign-up">Create
                                                        One</Link></p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <p className="rd-site-reserved d-none d-lg-block">All rights reserved. © 2022 Om Outline</p>
                            </div>
                        </div>
                        <div className="col col-12 col-md-12 col-lg-6">
                            {window.innerWidth > 767 ?
                                <div className="rd-login-img">
                                    <img src="assets/images/login_img.jpg" alt="login-img" className="img-fluid w-100" />
                                </div>
                                : ""}
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Login