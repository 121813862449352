import React, { useEffect, useState } from "react";
import axios from 'axios';

// import {
//   useStripe, useElements,
//   CardNumberElement, CardExpiryElement, CardCvcElement
// } from '@stripe/react-stripe-js';
import { OM_API_URL } from "../BaseURL";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePaymentInputs } from 'react-payment-inputs';
import { toast, Toaster } from "react-hot-toast";


export default function CheckoutForm() {
  // const stripe = useStripe();
  // const elements = useElements();

  // const CARD_ELEMENT_OPTIONS = {
  //   style: {
  //     base: {
  //       lineHeight: "27px",
  //       color: "#A5A5A5",
  //       "::placeholder": {
  //         color: "#AAB7C4",
  //       },
  //       backgroundColor: '#FFFFFF',
  //     },
  //     invalid: {
  //       color: "#FA755A",
  //       iconColor: "#FA755A",
  //     },
  //   },
  // };

  const [name, setName] = useState('');
  const [planName, setPlanName] = useState({ card_name: "", card_number: "", exp: "", cvc: "", card_holder: "" })
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const nav = useNavigate();
  const location = useLocation();
  const id = location.state;

  // const HandleCard = async () => {

  //   if (name) {
  //     setError({ ...error, name_err: null })


  //     // console.log(card.exp_month)
  //     // console.log(card.exp_year)

  //     // const card = paymentMethod.card;
  //     const { err, paymentMethod } = await stripe.createPaymentMethod({
  //       type: 'card',
  //       // card: elements.getElement(CardNumberElement),
  //       card: {
  //         number: elements.getElement(CardNumberElement),
  //         cvc: elements.getElement(CardCvcElement),
  //       },
  //       billing_details: {
  //         name,
  //       },
  //     })



  //     console.log(name, "detail;s")

  //     if (!err) {
  //       const bodyFormData = new URLSearchParams();
  //       bodyFormData.append('subscription_plan_id', "64108543f6aea9554803c299");
  //       bodyFormData.append('card_number', "4111 1111 1111 1111");
  //       bodyFormData.append('exp_month', "12");
  //       bodyFormData.append('exp_year', "23");
  //       bodyFormData.append('cvc', "123");
  //       bodyFormData.append('card_holder', name);

  //       setIsLoading(true);
  //       axios({
  //         method: 'post',
  //         url: `${OM_API_URL}/customer/start-subscription`,
  //         data: bodyFormData,
  //         headers: { 'Content-Type': 'application/x-www-form-urlencoded ', 'Authorization': `Bearer ${localStorage.getItem('OM_TOKEN_USER')}` }
  //       }).then((response) => {
  //         console.log(response?.data?.message);
  //         if (response.data?.status === true) {

  //           toast.success(response.data?.message, { style: { background: '#333', color: '#fff' } });
  //           setTimeout(() => {
  //             nav('/');
  //           }, [1000])
  //         }
  //         else {
  //           toast.error(response.data.message, { style: { background: '#333', color: '#fff' } })
  //         }
  //         setIsLoading(false)
  //       }).catch((err) => {
  //         setError({ ...error, password_err: err.response.data.message })
  //         toast.error(err.response.data.message, { style: { background: '#333', color: '#fff' } })
  //         setIsLoading(false)
  //         nav('/')
  //         console.log("erroro subs 23")
  //       })
  //     } else {
  //       setError({ ...error, name_err: null, card_err: err })
  //       console.log("erroro subs");
  //     }
  //   }
  //   else {
  //     setError({ ...error, name_err: "Enter Name" })
  //   }
  // }

  // const cardElementHandler = (e, type) => {
  //   if (type == "name_err") {
  //     console.log(name);
  //     if (name == "" || name == null)
  //       setError({ ...error, [type]: null })
  //     setError({ ...error, [type]: "Enter Name" })
  //   }
  //   if (e.error) {
  //     setError({ ...error, [type]: e.error.message })
  //     console.log(error)
  //     //displayError.textContent = e.error.message;
  //   } else {
  //     setError({ ...error, [type]: null })

  //   }
  // };

  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs();

  const HandleCard = () => {
    if (validation()) {
      const bodyFormData = new URLSearchParams();
      bodyFormData.append('subscription_plan_id', id);
      bodyFormData.append('card_number', planName.card_number);
      bodyFormData.append('exp_month', planName.exp);
      bodyFormData.append('exp_year', planName.exp);
      bodyFormData.append('cvc', planName.cvc);
      bodyFormData.append('card_holder', name);
      bodyFormData.append('type', "1")

      setIsLoading(true);
      axios({
        method: 'post',
        url: `${OM_API_URL}/customer/start-subscription`,
        data: bodyFormData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded ', 'Authorization': `Bearer ${localStorage.getItem('OM_TOKEN_USER')}` }
      }).then((response) => {
        console.log(response?.data?.message);
        if (response.data?.status === true) {
          toast.success(response.data?.message, { style: { background: '#333', color: '#fff' } });
          setTimeout(() => {
            nav('/');
            window.location.reload();
          }, 1500)
        }
        else {
          toast.error(response.data.message, { style: { background: '#333', color: '#fff' } })
        }
        setIsLoading(false)
      }).catch((err) => {
        setError({ ...error, password_err: err.response.data.message })
        toast.error(err.response.data.message, { style: { background: '#333', color: '#fff' } })
        setIsLoading(false)
        nav('/')
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.removeItem('OM_TOKEN_USER')
          nav('/login')
        }
        // window.location.reload();
      })
    }
  }

  const validation = () => {
    var isValid = true;
    let err = {};

    if (!planName.card_name) {
      isValid = false;
      err['card_name_err'] = "Please enter your card name"
    }

    if (!planName.card_number) {
      isValid = false;
      err['card_number_err'] = "Please enter your card number "
    }
    if (!planName.exp) {
      isValid = false;
      err['exp_err'] = "Please enter your card expiry details "
    } else {
      const currentDate = new Date();
      // const currentYear = currentDate.getFullYear();
      const currentYear = currentDate.getFullYear().toString().slice(-2);
      const currentMonth = currentDate.getMonth() + 1; // Add 1 since getMonth() returns 0-indexed values (0-11)
      const [inputMonth, inputYear] = planName.exp.split('/').map((item) => (item.trim()));
      console.log(planName.exp.split('/').map((item) => (item.trim())),"exp data")

      if (isNaN(inputMonth) || isNaN(inputYear)) {
        isValid = false;
        err.exp_err = "Invalid expiration date format";
      } else if (inputYear < currentYear || (inputYear === currentYear && inputMonth < currentMonth) || (currentMonth === inputMonth)) {
        isValid = false;
        err.exp_err = "Expiration date has passed";
      } 
      // else if (inputMonth < currentMonth || (inputMonth === currentMonth && inputYear < currentYear)) {
      //   isValid = false;
      //   err.exp_err = "Invalid expiration month";
      // }
      else if( currentYear > inputYear || (currentYear === inputYear && currentMonth >= inputMonth)){
        err.exp_err ="The expiry date is before today's date. Please select a valid expiry date"
        return false;
    }
    }

    if (!planName.cvc) {
      isValid = false;
      err['cvc_err'] = "Please enter your card cvv number"
    }

    setError(err);
    return isValid;
  }


  const handleChange = (e) => {
    setPlanName({ ...planName, [e.target.name]: e.target.value })
  }

  return (

    <form id="payment-form">
      <Toaster />
      <h4 className="om-payment-title">Add New Credit Card</h4>
      <div className="manage-account-card">
        <Link to="#"><img src="assets/images/visa.png" alt="" className="img-fluid" /></Link>
        <Link to="#"><img src="assets/images/mastercard.png" alt="" className="img-fluid" /></Link>
        <Link to="#"><img src="assets/images/amex.png" alt="" className="img-fluid" /></Link>
        <Link to="#"><img src="assets/images/discover.png" alt="" className="img-fluid" /></Link>
      </div>
      <div className="rd-dashboard-form rd-manage-account-form">
        <form>
          <div className="rd-customer-info-form subscription-input">
            <div className="row">
              <div className="col col-12">
                <label htmlFor="inputCompany" className="form-label">Credit Card Holder</label>
                <input type="text" className="form-control" id="inputBillingAddress" name='card_name' onChange={handleChange} />
                <span className='error'>{error.card_name_err}</span>
              </div>

              <div className="col col-12">
                <label htmlFor="inputCompany" className="form-label">Credit Card Number</label>
                <input type="number" className="form-control" {...getCardNumberProps({ onChange: handleChange })} name='card_number' value={planName.card_number} />
                <span className='error'>{error.card_number_err}</span>

              </div>
              <div className="col col-12 col-md-6">
                <label htmlFor="inputCompany" className="form-label">Expiration</label>
                <input className="form-control" {...getExpiryDateProps({ onChange: handleChange })} name='exp' value={planName.exp} />
                <span className='error'>{error.exp_err}</span>
              </div>

              <div className="col col-12 col-md-6">
                <label htmlFor="inputCompany" className="form-label">CVV</label>
                <input className="form-control" {...getCVCProps({ onChange: handleChange })} value={planName.cvc} name="cvc" />
                <span className='error'>{error.cvc_err}</span>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="rd-subscription-btn om-payment-btn">
        <button type="button" className="btn btn-dark" onClick={HandleCard} disabled={isLoading}>Save & Pay</button>
      </div>
    </form>
  );
}