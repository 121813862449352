import React, { useEffect, useRef, useState } from 'react'
import Footer from './Footer'
import Header from './Header'

import { OM_API_URL } from '../BaseURL';
import { useNavigate } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import axios from 'axios';

const Classes = () => {

    const [extentShow, setExtentShow] = useState({
        slide1: false,
        slide2: false,
        slide3: false,
        slide4: false,
        slide5: false,
        slide6: false,
    });

    const extraContent = (str, ind) => {
        if (extentShow[`slide${ind + 1}`] === false) {
            return `${str.slice(0, 290)}...`
        } else {
            return str;
        }
    }
    // const [content, setContent] = useState([
    //     {
    //         title: `Mindfulness Training for Athletes`,
    //         desc: `Through a blend of meditation, mindfulness techniques, and tailored exercises, 
    //         athletes will learn to cultivate inner stillness, enhance focus, and manage stress, 
    //         ultimately unlocking their full potential both on and off the field. Join us on this
    //          transformative journey to achieve optimal performance and well-being in sports and life.            `,
    //         img: `assets/images/Mindfulness Training For Athletes.jpg`
    //     },
    //     {
    //         title: `Transforming Trauma`,
    //         desc: `Discover your innate ability to transform trauma and embark on a path of healing
    //          and growth. Mindfulness means paying attention to the moment. In this class we will
    //           use the breath as an anchor as we notice what is happening around with an open heart
    //            and without judgment.`,
    //         img: `assets/images/Transforming Trauma.jpg`
    //     },
    //     {
    //         title: `Mindfulness for Re-Entry`,
    //         desc: `Explore mindfulness practices, guided meditation, and self-reflection to navigate
    //          the process of returning to society after incarceration. Cultivate emotional resilience,
    //           release burdens, and foster a peaceful mindset to discover inner freedom.             `,
    //         img: `assets/images/Mindfulness For Re-Entry.jpg`
    //     },
    //     {
    //         title: `Love Everyone Including Yourself`,
    //         desc: `Metta or Lovingkindness is a meditation with roots in Buddhism. Traditionally,
    //          a practitioner places focus on the phrases: May you be safe, May you be happy,
    //           May you be healthy, May you be free from all suffering. These phrases are an offering
    //            or gesture and cultivate a deep compassion for oneself and the world. This practice helps
    //             us remember that Metta is infinite, the more we practice it, the more that becomes available. `,
    //         img: `assets/images/Love Everyone Including Yourself.jpg`


    //     },
    //     {
    //         title: `Anxiety`,
    //         desc: `Feeling stressed? Anxious? Overwhelmed? This short practice uses practical breathing 
    //         and mindfulness techniques to help manage the impact of anxiety on the body and mind.
    //          If you feel like you're too busy to take this class, then it's perfect for you. `,
    //         img: `assets/images/Anxiety.jpg`
    //     },
    //     {
    //         title: `Breathe & Be`,
    //         desc: `Prana is our life force/energy. Pranayama practices are techniques 
    //         that use the breath to work with our life force. You'll master various breathing
    //          techniques to fulfill different needs. Whether you're looking to improve your energy,
    //           focus, mindfulness, manage anxiety or sleep better, pranayama is your answer.`,
    //         img: `assets/images/Breath & Be.jpg`
    //     },
    // ])

    const [content, setContent] = useState([]);

    useEffect(() => {
        getClassData();
    })

    const getClassData = () => {
        const myurl = `${OM_API_URL}/customer/get-classes`;

        axios({
            method: "get",
            url: myurl,
            // data: fd,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Bearer " + localStorage.getItem('OM_Admin_ID') },
        }).then(async (response) => {
            console?.log("@@", response?.data);
            if (response?.data?.status) {
                setContent(response?.data?.data)
            } else {
                toast.error("Something went wrong");
            }
        }).catch((error) => {
            toast.error("Something went wrong");
            console?.log("Errors", error);
        });
    }

    const storyRef = useRef();

    useEffect(() => {
        storyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, [])

    return (
        <div>
            <Header />
            <section className="om-landing-section">
                <div className="om-landing-content">
                    <img src="assets/images/home-landing-img.jpg" alt="home-landing-img" className="img-fluid w-100" />
                    <div className="om-landing-content__inner">
                        <div className="om-landing-content-text">
                            <div className="container">
                                <h1 className="section-title">Your home for healing</h1>
                                <p className="section-description">The first app designed to help manage trauma and stress
                                    through
                                    live classes and exclusive courses</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center om-landing-clases-btn">
                        <a href="#" className="btn btn-light">Download Our App</a>
                    </div>
                </div>
            </section>

            {/* <!-- Classes Section Start --> */}
            <section className="classes-section" ref={storyRef}>
                <div className="container">
                    <div className="classes-content">
                        <h2 className="section-title" style={{ marginBottom: "25px" }}>OM Live Classes</h2>
                        {content?.map((elem, index) =>
                            <div className="class-item">
                                <div className="row">
                                    <div className="col col-12 col-md-6 col-lg-7">
                                        <div className="class-item-text" >
                                            <h2 className="class-item-title">{elem.title}</h2>
                                            <p className="class-item-description">{elem?.description?.length > 290 ? extraContent(elem?.description, index) : elem?.description}{elem?.description?.length > 290 ? <b style={{ marginLeft: '10px', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { setExtentShow({ ...extentShow, [`slide${index + 1}`]: !extentShow[`slide${index + 1}`] }) }}>{extentShow[`slide${index + 1}`] ? 'Read less' : 'Read more'}</b> : ''}</p>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-lg-5">
                                        <div className="class-item-img" style={{ textAlign: 'center' }}>
                                            <img src={elem.image} alt="classes-img" className="img-fluid" style={{ width: "100%", height: "250px", objectFit: "cover" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* {content.length > 0 && content.map((elem, index) => {
                            const description = elem.desc;
                            const isLongDescription = description.length > 270;
                            const truncatedDescription = isLongDescription ? description.slice(0, 270) + '...' : description;

                            const toggleDescription = () => {
                                setShowFullDescription(!showFullDescription);
                            };

                            return (
                                <div className="class-item" key={index}>
                                    <div className="row">
                                        <div className="col col-12 col-md-6 col-lg-7">
                                            <div className="class-item-text">
                                                <h2 className="class-item-title">{elem.title}</h2>
                                                <p className="class-item-description">
                                                    {showFullDescription ? description : truncatedDescription}
                                                    {isLongDescription && (
                                                        <span className="read-more-link" onClick={toggleDescription}>
                                                            {showFullDescription ? "Read less" : "Read more"}
                                                        </span>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col col-12 col-md-6 col-lg-5">
                                            <div className="class-item-img">
                                                <img src={elem.img} alt="classes-img" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })} */}

                        {/* <div className="class-item">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-7">
                                    <div className="class-item-text">
                                        <h2 className="class-item-title">Metta Meditation</h2>
                                        <p className="class-item-description">Metta or Lovingkindness is a meditation with roots
                                            in Buddhism. Traditionally, a practitioner places focus on the phrases: May you
                                            be safe, May you be happy, May you be healthy, May you be free from all
                                            suffering. These phrases are an offering or gesture and cultivate a deep
                                            compassion for oneself and the world. This practice helps us remember that Metta
                                            is infinite, the more we practice it, the more that becomes available. There are
                                            10 minute versions and 20 minute versions of this practice.</p>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-5">
                                    <div className="class-item-img">
                                        <img src="assets/images/classes-img.png" alt="classes-img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="class-item">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-7">
                                    <div className="class-item-text">
                                        <h2 className="class-item-title">Mindfulness Meditation</h2>
                                        <p className="class-item-description">Mindfulness means paying attention to the moment .
                                            In this class we will use the breath as an anchor as we notice what is happening
                                            around with an open heart and without judgment.  This practice is for all those
                                            who say, 'I can't meditate because I can't empty my mind.' This practice is for
                                            you.  Class lengths are 10, 15 and 30 minutes.</p>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-5">
                                    <div className="class-item-img">
                                        <img src="assets/images/classes-img2.png" alt="classes-img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="class-item">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-7">
                                    <div className="class-item-text">
                                        <h2 className="class-item-title">Anxiety</h2>
                                        <p className="class-item-description">Feeling stressed? Anxious? Overwhelmed? This short
                                            practice uses practical breathing and mindfulness techniques to help manage the
                                            impact of anxiety on the body and mind. If you feel like you're too busy to take
                                            this class, then it's perfect for you. Class is 5 minutes</p>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-5">
                                    <div className="class-item-img">
                                        <img src="assets/images/classes-img3.png" alt="classes-img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="class-item">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-7">
                                    <div className="class-item-text">
                                        <h2 className="class-item-title">Breathe & Be’ Pranayama</h2>
                                        <p className="class-item-description">Prana is our life force/energy. Pranayama
                                            practices are techniques that use the breath to work with our life force. You'll
                                            master various breathing techniques to fulfill different needs. Whether you're
                                            looking to improve your energy, focus, mindfulness, manage anxiety or sleep
                                            better. Pranayama is your answer. Various classes at different lengths. 10, 15
                                            and 20 minutes.</p>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-5">
                                    <div className="class-item-img">
                                        <img src="assets/images/classes-img4.png" alt="classes-img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="class-item">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-7">
                                    <div className="class-item-text">
                                        <h2 className="class-item-title">Sound Healing</h2>
                                        <p className="class-item-description">This class is an invitation to explore the
                                            foundations of being in the present moment using the power of sound. We will
                                            learn practical tools to increase feelings of connection, wellbeing, focus, and
                                            creativity in our daily lives. You will learn sound as an anchor, sound and the
                                            body, sound and emotion, sound outside and sounds and thinking. Classes are 15,
                                            30 and 45 minutes</p>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-5">
                                    <div className="class-item-img">
                                        <img src="assets/images/classes-img5.png" alt="classes-img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="class-item">
                            <div className="row">
                                <div className="col col-12 col-md-6 col-lg-7">
                                    <div className="class-item-text">
                                        <h2 className="class-item-title">Kids Mindfulness</h2>
                                        <p className="class-item-description">This class is suitable for 8-12 year olds and
                                            discusses mindfulness and the role it plays in dealing with emotions. The
                                            practices use imagery, storytelling and playful movement and create a space for
                                            kids to breathe and be. Several options for kids classes that are 5, 10 and 15
                                            minutes in length.</p>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-lg-5">
                                    <div className="class-item-img">
                                        <img src="assets/images/classes-img6.png" alt="classes-img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            {/* <!-- Classes Section End --> */}
            <Footer />
        </div>
    )
}

export default Classes