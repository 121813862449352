import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import Login from './components/Login';
import About from './components/About';
import Blog from './components/Blog';
import SignUp from './components/SignUp';
import BlockDetails from './components/BlockDetails';
import EmailSubscription from './components/EmailSubscription';
import EmailRedeem from './components/EmailRedeem';
import Classes from './components/Classes';
import Otp from './components/Otp';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import SubscriptionPlan from './components/SubscriptionPlan';
import HowItWorks from './components/HowItWorks';
import CheckoutForm from './components/Checkout';
import Payment from './components/Payment';
import TermsCondition from './components/TermsCondition';
import { useEffect } from 'react';
import PrivacyPolicy from './components/PrivacyPolicy';
import ContactUs from './components/ContactUs';
import Group from './components/Group';

function App() {
  useEffect(() => {
    window.onpopstate = () => {
      window.location.reload();
    }
  })
  return (
    <div className="App">
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/login' exact element={<Login />} />
        <Route path='/sign-up' exact element={<SignUp />} />
        <Route path='/group' exact element={<Group />} />
        <Route path='/email-subscription' exact element={<EmailSubscription />} />
        <Route path='/email-redeem-subscription' exact element={<EmailRedeem />} />

        <Route path='/about-us' exact element={<About />} />
        <Route path='/classes' exact element={<Classes />} />

        <Route exact path='/otp-verification' element={<Otp />} />
        <Route exact path='/forgot-password' element={<ForgotPassword />} />
        <Route exact path='/reset-password' element={<ResetPassword />} />

        <Route exact path='/subscription-plan' element={<SubscriptionPlan />} />
        <Route exact path='/how-it-works' element={<HowItWorks />} />
        <Route exact path='/checkout' element={<CheckoutForm />} />

        <Route exact path='/payment' element={<Payment />} />

        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route exact path='/terms-and-conditions' element={<TermsCondition />} />

        <Route path='/blog' exact element={<Blog />} />
        <Route path='/blog-details' exact element={<BlockDetails />} />

        <Route path='/contact-us' exact element={<ContactUs />} />
      </Routes>
    </div>
  );
}

export default App;
