import React, { useState, useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import axios from 'axios'
import { OM_API_URL } from '../BaseURL';
import { useNavigate } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';


const Home = () => {

    useEffect(() => {
        getSubscriptionPlan();
        getPlan();
        getHomeData();
    }, []);

    const [plans, setPlans] = useState([]);
    const [plansTwo, setPlansTwo] = useState([]);

    const [planDetails, setPlanDetails] = useState([])
    const [currentPlanId, setCurrentPlanId] = useState([]);

    const [home, setHome] = useState({ title: "", sub_title: "", image: "" });

    const navigate = useNavigate();

    const getHomeData = () => {
        axios({
            method: 'get',
            url: `${OM_API_URL}/customer/get-homes`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then((response) => {
            console.log('@@$$', response?.data)
            if (response.data.status) {
                let data = response?.data?.data
                setHome({
                    title: data?.title,
                    sub_title: data?.sub_title,
                    image: data?.image
                })
            }
        }).catch((error) => {
            console.log(error, "error")
        })
    }


    const getSubscriptionPlan = () => {
        axios({
            method: 'post',
            url: `${OM_API_URL}/customer/get-subscription-plans`,
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('OM_TOKEN_USER')}` }
        }).then(async (response) => {
            if (response.data.status === true) {
                // console.log(response.data.data.plans)
                let data = await Promise.all(response.data.data.plans.map(elem => {
                    return { ...elem, description: elem.description.split('\n') }
                }))
                setPlans(data)
                if (response.data.data.active_plan !== '') {
                    const detail = response.data.data.active_plan
                    setPlanDetails(detail)
                    setCurrentPlanId(detail._id)
                }
            }
        }).catch((err) => {
            console.log(err)
            if (err.response.status === 401) {
                localStorage.removeItem('OM_TOKEN_USER')
                navigate('/login')
            }
        })
    }

    const getPlan = () => {
        axios({
            method: 'get',
            url: `${OM_API_URL}/web/get-subscription-plans`,
            headers: { 'Content-Type': 'application/json' }
        }).then(async (response) => {
            if (response.data.status === true) {
                let data = await Promise.all(response.data.data.map(elem => {
                    return { ...elem, description: elem.description.split('\n') }
                }))
                setPlansTwo(data)
            }
        }).catch((err) => {
            console.log(err, "error")
            if (err.response.status === 401) {
                localStorage.removeItem('OM_TOKEN_USER')
                navigate('/login')
            }
        })
    }

    const handleUserSubscription = (id) => {
        if (localStorage.getItem('OM_TOKEN_USER') === null) {
            navigate('/sign-up')
        } else {
            navigate('/payment', { state: id })
        }
    }

    const checkPlan = (id) => {
        let isVlaid = false;
        if (plans.filter(pl => pl._id === id)[0].amount === 0 && currentPlanId !== id) { isVlaid = true } else { isVlaid = false }
        return isVlaid
    }


    const handleUserSubscriptionFree = (id) => {
        if (localStorage.getItem('OM_TOKEN_USER') === null) {
            navigate('/sign-up')
        }
        else {
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('subscription_plan_id', id);
            axios({
                method: 'post',
                url: `${OM_API_URL}/customer/start-subscription`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded ', 'Authorization': `Bearer ${localStorage.getItem('OM_TOKEN_USER')}` }
            }).then((response) => {
                if (response.data?.status === true) {
                    // toast.success(response.data?.message, { style: { background: '#333', color: '#fff' } });
                    toast.success(" Congratulations you’re subscribed", { style: { background: '#333', color: '#fff' } });
                    setTimeout(() => {
                        navigate('/');
                    }, [1000])
                    console.log("data subscribe")
                }
                else {
                    console.log("data subscribe not subscribe")
                    // toast.error(response.data.message, { style: { background: '#333', color: '#fff' } })
                }
            }).catch((err) => {
                toast.error(err.response.data.message, { style: { background: '#333', color: '#fff' } });
            })
        }
    }

    const handleBusiness = () => {
        window.location.href = "https://group.omathomeapp.com/login";
    }

    const redirectToStore = () => {
        const userAgent = navigator.userAgent;
        if (/Android/.test(userAgent)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.omoutline.user';
        } else if (/iPhone|iPad|iPod/.test(userAgent)) {
            window.location.href = 'https://apps.apple.com/us/app/om-at-home/id1662076161';
        } else {
            console.log('Unsupported device.');
        }
    };

    return (
        <div>
            <Toaster />
            <Header />

            <button type="button" className="btn-download" onClick={redirectToStore}>Download Now</button>

            <section className="om-landing-section">
                <div className="om-landing-content">
                    <img src={home.image}
                        //  alt="home-landing-img"
                        className="img-fluid w-100 " />
                    <div className="om-landing-content__inner">
                        <div className="om-landing-content-text">
                            <div className="container">
                                {/* <h1 className="section-title">Your home for healing</h1> */}
                                <h1 className='section-title'>{home.title}</h1>
                                {/* <p className="section-description">The first app designed to help manage trauma and stress
                                        through
                                        live classes and exclusive courses</p> */}
                                <p className='section-description'>{home.sub_title}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Works Section Start --> */}
            <section className="works-section" id="works">
                <div className="container">
                    <div className="works-content">
                        <h2 className="section-title">HOW IT WORKS</h2>
                        <div className="works-slider safari">
                            <div className="works-slider-item" >
                                <h2 className="section-description works-name">
                                    <span>01</span>
                                    <p>Sign up for free</p>
                                </h2>
                                <div className="works-slider-img">
                                    <img src="assets/images/works_1.jpg" alt="" className="img-fluid" />
                                </div>
                            </div>
                            <div className="works-slider-item" >
                                <h2 className="section-description works-name">
                                    <span>02</span>
                                    {/* <p>Join a live event or add an event to your calendar</p> */}
                                    <p>Join a live class or add a class to your calendar</p>
                                </h2>
                                <div className="works-slider-img">
                                    <img src="assets/images/works_2.png" alt="" className="img-fluid" />
                                </div>

                            </div>
                            <div className="works-slider-item" >
                                <h2 className="section-description works-name">
                                    <span>03</span>
                                    <p>Experience the healing power of our sacred space</p>
                                </h2>
                                <div className="works-slider-img">
                                    <img src="assets/images/works_3.svg" alt="" className="img-fluid" />
                                </div>

                            </div>
                            {/* <div className="works-slider-item">
                                <h2 className="section-description works-name">
                                    <span>01</span>
                                    <p>Sign up for free</p>
                                </h2>
                                <div className="works-slider-img">
                                    <img src="assets/images/works_1.jpg" alt="" className="img-fluid" />
                                </div>
                            </div>
                            <div className="works-slider-item">
                                <h2 className="section-description works-name">
                                    <span>02</span>
                                    <p>Join a live event or add an event to your calendar</p>
                                </h2>
                                <div className="works-slider-img">
                                    <img src="assets/images/works_2.png" alt="" className="img-fluid" />
                                </div>
                            </div>
                            <div className="works-slider-item">
                                <h2 className="section-description works-name">
                                    <span>03</span>
                                    <p>Experience the healing power of our sacred space</p>
                                </h2>
                                <div className="works-slider-img">
                                    <img src="assets/images/works_3.svg" alt="" className="img-fluid" />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Works Section End --> */}

            {/* <!-- Subscription Section Start --> */}
            {/* <section className="subscription-section" id="subscription">
                <div className="container">
                    <div className="subscription-content">
                        <h2 className="section-title">Choose Your Plan </h2>
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-individual-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-individual" type="button" role="tab"
                                    aria-controls="pills-individual" aria-selected="true">Individual</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-business-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-business" type="button" role="tab" aria-controls="pills-business"
                                    aria-selected="false">Business</button>
                            </li>
                        </ul>
                        <div className="tab-content subscription-tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-individual" role="tabpanel"
                                aria-labelledby="pills-individual-tab">
                                <div className="row">
                                    <div className="col col-12 col-md-6 col-lg-4">
                                        <div className="subscription-plans">
                                            <div className="subscription-plans-details">
                                                <h4 className="subscription-title">Basic</h4>
                                                <h3 className="subscription-price free">Free</h3>
                                                <ul className="subscription-plans-feature">
                                                    <li>2 live streamed events per month</li>
                                                    <li>Upgrade your plan as needed</li>
                                                    <li>No credit card needed</li>
                                                </ul>
                                            </div>
                                            <div className="subscription-plans-footer">
                                                <a href="#" className="btn btn-subscription">Try For Free</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-lg-4">
                                        <div className="subscription-plans active">
                                            <div className="subscription-plans-details">
                                                <h4 className="subscription-title">Monthly Individual</h4>
                                                <h3 className="subscription-price">$12.99/ month</h3>
                                                <ul className="subscription-plans-feature">
                                                    <li>Full app access</li>
                                                    <li>Unlimited live streamed events</li>
                                                    <li>Unlimited access to exclusive courses </li>
                                                </ul>
                                            </div>
                                            <div className="subscription-plans-footer">
                                                <a href="#" className="btn btn-subscription">Subscribe</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-lg-4">
                                        <div className="subscription-plans">
                                            <div className="subscription-plans-details">
                                                <h4 className="subscription-title">6 Month Business </h4>
                                                <h3 className="subscription-price ">$589/month $1/month per user</h3>
                                                <ul className="subscription-plans-feature">
                                                    <li>Add up to 100 users by using just email addresses</li>
                                                    <li>Access to user management dashboard</li>
                                                    <li>Users have unlimited access to live-streamed events</li>
                                                    <li>Users have unlimited access to exclusive courses</li>
                                                </ul>
                                            </div>
                                            <div className="subscription-plans-footer">
                                                <a href="#" className="btn btn-subscription">Subscribe</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-business" role="tabpanel"
                                aria-labelledby="pills-business-tab">
                                <div className="row">
                                    <div className="col col-12 col-md-6 col-lg-4">
                                        <div className="subscription-plans">
                                            <div className="subscription-plans-details">
                                                <h4 className="subscription-title">Basic</h4>
                                                <h3 className="subscription-price free">Free</h3>
                                                <ul className="subscription-plans-feature">
                                                    <li>2 live streamed events per month</li>
                                                    <li>Upgrade your plan as needed</li>
                                                    <li>No credit card needed</li>
                                                </ul>
                                            </div>
                                            <div className="subscription-plans-footer">
                                                <a href="#" className="btn btn-subscription">Try For Free</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-lg-4">
                                        <div className="subscription-plans active">
                                            <div className="subscription-plans-details">
                                                <h4 className="subscription-title">Monthly Individual</h4>
                                                <h3 className="subscription-price">$12.99/ month</h3>
                                                <ul className="subscription-plans-feature">
                                                    <li>Full app access</li>
                                                    <li>Unlimited live streamed events</li>
                                                    <li>Unlimited access to exclusive courses </li>
                                                </ul>
                                            </div>
                                            <div className="subscription-plans-footer">
                                                <a href="#" className="btn btn-subscription">Subscribe</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-lg-4">
                                        <div className="subscription-plans">
                                            <div className="subscription-plans-details">
                                                <h4 className="subscription-title">6 Month Business </h4>
                                                <h3 className="subscription-price ">$589/month $1/month per user</h3>
                                                <ul className="subscription-plans-feature">
                                                    <li>Add up to 100 users by using just email addresses</li>
                                                    <li>Access to user management dashboard</li>
                                                    <li>Users have unlimited access to live-streamed events</li>
                                                    <li>Users have unlimited access to exclusive courses</li>
                                                </ul>
                                            </div>
                                            <div className="subscription-plans-footer">
                                                <a href="#" className="btn btn-subscription">Subscribe</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section> */}

            <section className="subscription-section" id="subscription">
                <div className="container">
                    <div className="subscription-content">
                        <h2 className="section-title">Choose Your Plan </h2>
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-individual-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-individual" type="button" role="tab"
                                    aria-controls="pills-individual" aria-selected="true">Individual</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-business-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-business" type="button" role="tab" aria-controls="pills-business"
                                    aria-selected="false">Group</button>
                            </li>
                        </ul>
                        <div className="tab-content subscription-tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-individual" role="tabpanel"
                                aria-labelledby="pills-individual-tab">
                                <div className="row">
                                    {plans.length > 0 && plans.map((elem, index) =>
                                        <div className="col col-4 col-md-6 col-lg-4">
                                            <div className={`subscription-plans ${index === 1 ? 'active' : ''}`} key={elem.id}>
                                                <div className="subscription-plans-details">
                                                    <h4 className="subscription-title">{elem.plan_name}</h4>

                                                    {index === 1 ? (
                                                        <h3 className="subscription-price free">
                                                            $ {elem.amount}
                                                            {/* <p className='subscription-price-plan'> $6.58/ month </p> */}
                                                        </h3>
                                                    ) : (
                                                        <h3 className="subscription-price">
                                                            $ {elem.amount}
                                                        </h3>
                                                    )}
                                                    {elem.description.map(e =>
                                                        <ul className="subscription-plans-feature">
                                                            <li>{e.split('. ')[1]}</li>
                                                        </ul>
                                                    )}
                                                </div>
                                                <div className="subscription-plans-footer">
                                                    {elem.amount === 0 ? (
                                                        <button type="button" onClick={() => handleUserSubscriptionFree(elem._id)}
                                                            disabled={currentPlanId ? checkPlan(elem._id) : false} className="btn btn-subscription">
                                                            {elem._id === currentPlanId ? 'Current Plan - Try For Free' : 'Try For Free'}
                                                        </button>
                                                    ) : (
                                                        <button type="button" onClick={() => handleUserSubscription(elem._id)}

                                                            disabled={(elem._id === currentPlanId) || (planDetails.duration === 'Yearly plan')}
                                                            className="btn btn-subscription">
                                                            {elem._id === currentPlanId ? 'Current Plan' : 'Subscribe'}
                                                        </button>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-business" role="tabpanel"
                                aria-labelledby="pills-business-tab">
                                <div className="row" style={{ display: "flex", justifyContent: "center" }}>
                                    {plansTwo.length > 0 && plansTwo.map((element, index) =>
                                        <div className="col col-4 col-md-6 col-lg-4">
                                            <div className={`subscription-plans ${index === 1 ? 'active' : ''}`} key={element.id}>

                                                <div className="subscription-plans-details">
                                                    <h4 className="subscription-title">{element.plan_name}</h4>

                                                    {index === 0 ? (
                                                        <h3 className="subscription-price free">
                                                            $ {element.amount}
                                                            {/* <p className='subscription-price-plan-details'> $1/month per user </p> */}
                                                        </h3>
                                                    ) : (
                                                        <h3 className="subscription-price">
                                                            $ {element.amount}
                                                            {/* <p className='subscription-price-plan-details'>$6.58/month</p> */}
                                                        </h3>
                                                    )}

                                                    {element.description.map(e =>
                                                        <ul className="subscription-plans-feature">
                                                            <li>{e.split('. ')[1]}</li>
                                                        </ul>
                                                    )}
                                                </div>
                                                <div className="subscription-plans-footer">
                                                    <button type="button" onClick={handleBusiness} className="btn btn-subscription">Subscribe</button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* <div className='subs-new-plans'>
                            <div className="row" style={{ display: "flex", justifyContent: "center" }}>
                                {plans.slice(0, 2).map((elem, index) => (
                                    <div className="col col-4 col-md-6 col-lg-4" key={elem.id}>
                                        <div className={`subscription-plans ${index === 1 ? 'active' : ''}`}>
                                            <div className="subscription-plans-details">
                                                <h4 className="subscription-title">{elem.plan_name}</h4>
                                                {index === 1 ? (
                                                    <h3 className="subscription-price free">$ {elem.amount}</h3>
                                                ) : (
                                                    <h3 className="subscription-price">$ {elem.amount}</h3>
                                                )}
                                                {elem.description.map(e => (
                                                    <ul className="subscription-plans-feature" key={e}>
                                                        <li>{e.split('. ')[1]}</li>
                                                    </ul>
                                                ))}
                                            </div>
                                            <div className="subscription-plans-footer">
                                                {elem.amount === 0 ? (
                                                    <button
                                                        type="button"
                                                        onClick={() => handleUserSubscriptionFree(elem._id)}
                                                        disabled={currentPlanId ? checkPlan(elem._id) : false}
                                                        className="btn btn-subscription"
                                                    >
                                                        {elem._id === currentPlanId ? 'Current Plan - Try For Free' : 'Try For Free'}
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        onClick={() => handleUserSubscription(elem._id)}
                                                        disabled={(elem._id === currentPlanId) || (planDetails.duration === 'Yearly plan')}
                                                        className="btn btn-subscription"
                                                    >
                                                        {elem._id === currentPlanId ? 'Current Plan' : 'Subscribe'}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {plansTwo.length > 0 && (
                                    <div className="col col-4 col-md-6 col-lg-4" key={plansTwo[0].id}>
                                        <div className={`subscription-plans`}>
                                            <div className="subscription-plans-details">
                                              
                                                <h4 className="subscription-title">
                                                    {plansTwo[0].plan_name.split(' ').map((word, index) => (
                                                        <span key={index}>
                                                            {word}
                                                            {index === 0 ? <br /> : ' '}
                                                        </span>
                                                    ))}
                                                </h4>
                                                <h3 className="subscription-price free">$ {plansTwo[0].amount}</h3>
                                                {plansTwo[0].description.map(e => (
                                                    <ul className="subscription-plans-feature" key={e}>
                                                        <li>{e.split('. ')[1]}</li>
                                                    </ul>
                                                ))}
                                            </div>
                                            <div className="subscription-plans-footer">
                                                <button
                                                    type="button"
                                                    onClick={handleBusiness}
                                                    className="btn btn-subscription"
                                                >
                                                    Subscribe
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>

            <Footer />
            {/* <!-- Subscription Section End --> */}
        </div>
    )
}

export default Home