import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import axios from 'axios'
import { toast, Toaster } from 'react-hot-toast';
import { OM_API_URL } from '../BaseURL';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';


const About = () => {

    useEffect(() => {
        getCmsList()
    }, [])

    const [about, setAbout] = useState('')
    const navigate = useNavigate();
    console.log(about, "%%")

    const getCmsList = () => {
        const myurl = `${OM_API_URL}/customer/get-cms`;

        axios({
            method: "post",
            url: myurl,
            // data: fd,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
            .then(async (response) => {
                console?.log("@@", response?.data);
                if (response?.data?.status === true) {
                    setAbout(response?.data?.data?.user_cms?.about_us)
                } else {
                    toast.error("Something went wrong");
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
                console?.log("Errors", error);
                if (error.response.status === 401) {
                    localStorage.removeItem('OM_TOKEN_USER')
                    navigate('/login')
                }
            });
    };

    return (
        <div>
            <Toaster />
            <Header />
            <section className="about-section">
                <div className="container">
                    <div className="about-section-content">
                        <div className="row">
                            <div className="col col-12 col-md-4 col-lg-3">
                                <div className="about-section-img">
                                    <img src="assets/images/about-mob-new.jpg" alt="About" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col col-12 col-md-6 col-lg-6">
                                <div className="about-section-content">
                                    <h4 className="section-title">Welcome to OM At Home</h4>
                                    {/* <div className="about-section-description">
                                        <p>Our mindfulness and meditation app offers live classes and exclusive courses designed to address trauma
                                            and stress. Experience our range of live-streamed classes including "Mindfulness for Re-Entry,"
                                            created to support individuals transitioning back into society after incarceration, "Mindfulness
                                            for Athletes," empowering athletes to cultivate inner stillness, and "Transforming Trauma,"
                                            where you will learn grounding techniques using the power of breath.</p>
                                        <p>We offer subscription plans for individuals and groups, allowing up to 100 users per group subscription.
                                            Our team includes social justice leaders and mental health experts dedicated to inclusivity
                                            and authenticity. Unlike apps that focus on general stress and work burnout, Om At Home
                                            classes are led by world-class guides that provide a tailored approach to healing.</p>
                                    </div> */}
                                    <div className="about-section-description">
                                        <>
                                            {parse(about)}
                                        </>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default About