import React from 'react'
import Footer from './Footer'
import Header from './Header'

const HowItWorks = () => {

    return (
        <div>

            <Header />
            <section className="works-section" id="works">
                <div className="container">
                    <div className="works-content">
                        <h2 className="section-title">HOW IT WORKS</h2>
                        <div className="works-slider">
                            <div className="works-slider-item">
                                <h2 className="section-description works-name">
                                    <span>01</span>
                                    <p>Sign up for free</p>
                                </h2>
                                <div className="works-slider-img">
                                    <img src="assets/images/works_1.jpg" alt="" className="img-fluid" />
                                </div>

                            </div>
                            <div className="works-slider-item">
                                <h2 className="section-description works-name">
                                    <span>02</span>
                                    {/* <p>Join a live event or add an event to your calendar</p> */}
                                    <p>Join a live class or add a class to your calendar</p>
                                </h2>
                                <div className="works-slider-img">
                                    <img src="assets/images/works_2.png" alt="" className="img-fluid" />
                                </div>

                            </div>
                            <div className="works-slider-item">
                                <h2 className="section-description works-name">
                                    <span>03</span>
                                    <p>Experience the healing power of our sacred space</p>
                                </h2>
                                <div className="works-slider-img">
                                    <img src="assets/images/works_3.svg" alt="" className="img-fluid" />
                                </div>

                            </div>
                            {/* <div className="works-slider-item">
                                <div className="works-slider-img">
                                    <img src="assets/images/works_1.jpg" alt="" className="img-fluid" />
                                </div>
                                <h2 className="section-description works-name">
                                    <span>01</span>
                                    <p>Sign up for free</p>
                                </h2>
                            </div>
                            <div className="works-slider-item">
                                <div className="works-slider-img">
                                    <img src="assets/images/works_2.png" alt="" className="img-fluid" />
                                </div>
                                <h2 className="section-description works-name">
                                    <span>02</span>
                                    <p>Join a live event or add an event to your calendar</p>
                                </h2>
                            </div>
                            <div className="works-slider-item">
                                <div className="works-slider-img">
                                    <img src="assets/images/works_3.svg" alt="" className="img-fluid" />
                                </div>
                                <h2 className="section-description works-name">
                                    <span>03</span>
                                    <p>Experience the healing power of our sacred space</p>
                                </h2>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default HowItWorks