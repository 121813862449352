import React, { useEffect } from 'react'
import { OM_API_URL } from '../BaseURL';
// import { loadStripe } from "@stripe/stripe-js";
// import {
//     Elements
// } from '@stripe/react-stripe-js';

import CheckoutForm from "./Checkout";
import { useLocation, useNavigate } from 'react-router-dom';

// const stripePromise = loadStripe("pk_test_51MBjiMFiqjYMsHSsuj99rR6KYfGqeBMf5L1G6pfkBzg4azDn8g3OHVTNw6hPJFm0LziAsjJNdI2upNe4azgcpp35003evaVBvQ");

const Payment = () => {

    const navigate = useNavigate()

    useEffect(() => {
        if (localStorage.getItem('OM_TOKEN_USER') === null) {
            navigate('/sign-up')
        }
    }, [])

    const location = useLocation();

    return (
        <div>
            <main className="rd-site-content">
                <div className="rd-site-content__inner">
                    <div className="rd-page-head">
                        <button className="navbar-toggler" type="button">
                            <span className="navbar-toggler-icon"></span>
                            <span className="navbar-toggler-icon"></span>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <a href="/subscription-plan">
                        <svg xmlns="http://www.w3.org/2000/svg" height="30"
                         viewBox="0 0 24 24" width="30"><path d="M0 0h24v24H0z" fill="none" />
                         <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" /></svg>
                         </a>
                        {/* <h1 className="rd-dashboard-logged-name">Good Afternoon</h1> */}
                    </div>
                    <div className="rd-page-content">
                        <div className="rd-add-customer-content payment-content">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="rd-content-area">
                                        <div className="App">
                                            {/* {clientSecret && ( */}
                                            {/* <Elements stripe={stripePromise}>
                                                <CheckoutForm plan={location.state} />
                                            </Elements> */}
                                            <>
                                                <CheckoutForm plan={location.state} />
                                            </>
                                            {/* )} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Payment