import React, { useEffect, useState } from 'react'
import { useGoogleLogout } from 'react-google-login';
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const Header = ({ currentPage }) => {

    const isLoggedIn = localStorage.getItem("OM_TOKEN_USER")
    const nav = useNavigate()

    const handleLogout = () => {
        signOut();

        localStorage.removeItem('OM_TOKEN_USER');
        toast.success("Logout Successful..!");
        setTimeout(() => {
            nav('/')
            window.location.reload()
        }, 1000);
    }

    // useEffect(() => {
    //     if (!localStorage.getItem("OM_TOKEN_USER")) {
    //         signOut();
    //     }
    // }, [])


    const onFailure = (res) => {
        console.log('Sign-in failed', res);
    }

    const onSuccess = (res) => {
        console.log("Logout Successfully..", res)
    }
    const { signOut } = useGoogleLogout({
        clientId: "1047790433688-bm05f0ehoghcus8o8qn7el61ogdm46u3.apps.googleusercontent.com",
        onLogoutSuccess: onSuccess,
        onFailure,
    })

    // const clientId = '1047790433688-bm05f0ehoghcus8o8qn7el61ogdm46u3.apps.googleusercontent.com'

    // const onSuccess = () => {
    //     console.log("Logout Successfully..!")
    // }

    const handleNav = (val) => {
        nav(val)
        window.scrollTo(0, 0)
    }

    return (
        <div>
            <Toaster />
            <header className="header">
                <div className="container-fluid">
                    <div className="header-content">
                        <nav className="navbar navbar-expand-xl">
                            <a className="navbar-brand" href="/">
                                <img src="assets/images/logo-white.svg" style={{ height: "75px", width: "75px" }} alt="Header_Logo" className="img-fluid" />

                                {/* <svg width="70" height="70" viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_824_15343)">
                                        <path d="M78.5599 149.82C68.809 150.215 59.0756 148.686 49.9158 145.319C40.756 141.952 32.3492 136.814 25.1756 130.197C18.002 123.581 12.2021 115.616 8.10718 106.758C4.01226 97.8993 1.70254 88.3211 1.30994 78.57C0.5141 58.8766 7.57344 39.6736 20.9352 25.1848C34.2969 10.696 52.8667 2.10807 72.5599 1.30999C82.3119 0.915947 92.046 2.44658 101.206 5.81449C110.367 9.1824 118.774 14.3216 125.949 20.9387C133.123 27.5559 138.923 35.5213 143.019 44.3802C147.115 53.2391 149.426 62.818 149.82 72.57" stroke="white" stroke-width="2.5" stroke-miterlimit="10" />
                                        <path d="M56.5399 83.49C47.6899 83.49 43.2 76.81 43.2 69.13C43.2 61.45 47.7199 54.77 56.4999 54.77C65.6199 54.77 69.7999 61.91 69.7999 69.13C69.7999 76.81 65.2399 83.49 56.5399 83.49ZM65.9999 69.13C65.9999 62.52 62.1599 58.04 56.4599 58.04C50.7599 58.04 46.9999 62.52 46.9999 69.13C46.9999 75.74 50.7999 80.23 56.4999 80.23C62.1999 80.23 65.9999 75.7 65.9999 69.13Z" fill="white" />
                                        <path d="M79.34 55.49L87.1299 78.55H87.2099L94.9599 55.49H100.2V82.77H96.6299V66.51C96.6299 64.8 96.63 62.6 96.7 59.86H96.6299L88.95 82.77H85.3099L77.6299 59.9H77.5499C77.5499 61.99 77.6299 64.19 77.6299 66.51V82.77H74.0599V55.49H79.34Z" fill="white" />
                                        <path d="M105.13 82.77C107.035 82.77 108.58 81.2254 108.58 79.32C108.58 77.4146 107.035 75.87 105.13 75.87C103.225 75.87 101.68 77.4146 101.68 79.32C101.68 81.2254 103.225 82.77 105.13 82.77Z" fill="white" />
                                        <path d="M49.88 93.1H46.61L46.16 94.63H43.22L46.72 85.33H49.86L53.35 94.63H50.35L49.88 93.1ZM49.28 91.1L48.28 87.7599L47.28 91.1H49.28Z" fill="white" />
                                        <path d="M52.72 85.33H61.46V87.62H58.53V94.62H55.65V87.62H52.72V85.33Z" fill="white" />
                                        <path d="M67.13 85.33H70V88.58H73.13V85.33H76.01V94.63H73.13V90.9H69.98V94.66H67.13V85.33Z" fill="white" />
                                        <path d="M77.5599 89.9901C77.5222 89.3433 77.6152 88.6957 77.8334 88.0857C78.0516 87.4758 78.3906 86.9161 78.8299 86.4401C79.3035 86 79.8613 85.6606 80.4698 85.4423C81.0783 85.224 81.7247 85.1314 82.37 85.17C83.0201 85.1261 83.6725 85.2142 84.2877 85.429C84.903 85.6439 85.4684 85.981 85.95 86.42C86.3868 86.8851 86.7239 87.4347 86.9406 88.0349C87.1573 88.635 87.2489 89.2732 87.21 89.9101C87.2418 90.8344 87.0533 91.753 86.6599 92.5901C86.2975 93.2723 85.7453 93.8349 85.07 94.2101C84.2713 94.6242 83.3789 94.824 82.48 94.7901C81.5869 94.8191 80.6986 94.6483 79.8799 94.2901C79.1735 93.9401 78.5883 93.3862 78.2 92.7C77.7503 91.8694 77.5294 90.9341 77.5599 89.9901ZM80.4399 89.9901C80.3794 90.6966 80.563 91.4025 80.96 91.9901C81.1361 92.1945 81.3563 92.3565 81.6039 92.4639C81.8516 92.5712 82.1203 92.6212 82.3899 92.61C82.6582 92.6251 82.9264 92.579 83.1742 92.475C83.4219 92.371 83.6428 92.212 83.82 92.01C84.224 91.3625 84.4032 90.5998 84.3299 89.8401C84.3838 89.1577 84.1962 88.4781 83.7999 87.92C83.6198 87.719 83.3971 87.5608 83.1479 87.457C82.8987 87.3532 82.6295 87.3064 82.3599 87.3201C82.0971 87.3094 81.8353 87.3587 81.5945 87.4644C81.3536 87.5702 81.14 87.7294 80.9699 87.9301C80.5519 88.5347 80.3639 89.2689 80.4399 90.0001V89.9901Z" fill="white" />
                                        <path d="M88.72 85.33H92.5L93.96 90.99L95.41 85.33H99.19V94.63H96.83V87.54L95.02 94.63H92.89L91.08 87.54V94.63H88.72V85.33Z" fill="white" />
                                        <path d="M101.02 85.33H108.72V87.33H103.9V88.81H108.37V90.71H103.9V92.55H108.9V94.65H101.06L101.02 85.33Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_824_15343">
                                            <rect width="151.07" height="151.13" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg> */}



                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                                <span className="navbar-toggler-icon"></span>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav mx-auto">
                                    <li className="nav-item">
                                        <a className={`nav-link ${window.location.pathname === '/about-us' ? 'active' : ''}`} aria-current="page" href='' onClick={() => handleNav('/about-us')}>About</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${window.location.pathname === '/how-it-works' ? 'active' : ''}`} href="/how-it-works">How It Works</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${window.location.pathname === '/subscription-plan' ? 'active' : ''}`} href="/subscription-plan">Subscriptions</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${window.location.pathname === '/classes' ? 'active' : ''}`} href="/classes">Classes</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${window.location.pathname === '/blog' ? 'active' : ''}`} href="/blog">Blog</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${window.location.pathname === '/contact-us' ? 'active' : ''}`} href="/contact-us">Contact Us</a>
                                    </li>
                                </ul>
                            </div>


                            {isLoggedIn ? (
                                <div className="header-btn">
                                    <button type='button' className="btn btn-light" onClick={handleLogout}>
                                        Logout
                                    </button>
                                    {/* <GoogleLogout className='btn btn-light' clientId={clientId} buttonText={"Logout"} onLogoutSuccess={onSuccess} /> */}
                                </div>
                            ) : (
                                <div className="header-btn">
                                    <a href="/sign-up" className="btn btn-light free-try-btn">Sign Up</a>
                                    <a href='' onClick={() => handleNav('/login')} className="btn btn-outline-light individual-btn">Sign In</a>
                                    {currentPage !== 'group' && <a href="/group" className="btn btn-dark personal-btn">Group</a>}
                                </div>
                            )}
                        </nav>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header